import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { Button, Typography } from '@mui/material'
import {
  signupSteps,
  useSearchQueryParams,
} from '@local/Utils/Hooks/useSearchQueryParams'

export const FailedStep: React.FunctionComponent<{ failureReason: string }> = ({
  failureReason,
}) => {
  const t = useEpiCustomDictionary(['activity'])
  const history = useHistory()
  const { setSearchParamField } = useSearchQueryParams()

  useEffect(() => {
    if (history.action === 'POP') {
      setSearchParamField(signupSteps.Start, 'step')
    }
  })

  useEffect(() => {
    const backButton: HTMLElement = document.querySelector(
      "button[class^='Breadcrumbs_InternalBackLink']"
    )
    if (backButton) {
      backButton.style.display = 'none'
    }

    return () => {
      if (backButton) {
        backButton.style.display = 'initial'
      }
    }
  }, [])

  const handlePrimaryClick = useCallback(() => {
    history.push(t('findActivitiesUrl'))
  }, [history, t])

  return (
    <div style={{ textAlign: 'center' }} data-testid="failedStep">
      <Typography variant="h2" component="h1">
        {t('failHeading')}
      </Typography>
      <Typography variant="body1" mb="2rem">
        {t(
          failureReason ? `ERROR_${failureReason}` : 'unknownActivitySignError'
        )}
      </Typography>
      <Button size="large" onClick={handlePrimaryClick}>
        {t('failButtonText')}
      </Button>
    </div>
  )
}
