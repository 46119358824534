import getConfig from '@local/Utils/ConfigService/getConfig'

export type ActivityImageCategoryKeys =
  | 'customer'
  | 'jobsearch'
  | 'studies'
  | 'matching'
  | 'chef'
  | 'business'
  | 'generic'

export interface ImageCategories {
  customer: string[]
  jobsearch: string[]
  studies: string[]
  matching: string[]
  business: string[]
  chef: string[]
  generic: string[]
}
export interface ImageBank {
  customer: string[]
  jobsearch: string[]
  studies: string[]
  matching: string[]
  business: string[]
  chef: string[]
  generic: string[]
}

export enum ActivityCategories {
  SokJobb = 'Söka jobb',
  TraffaArbetsgivare = 'Träffa arbetsgivare',
  StartaForetag = 'Starta företag',
  Studera = 'Studera',
  PersonligUtveckling = 'Personlig utveckling',
}

export const imageCategorization = {
  jobsearch: [
    'Vässa ditt CV, brev och din Linkedin-profil',
    'Så tar du dig till intervjun',
    'Kom igång på TRR!',
    'Få koll på arbetsmarknaden och lär dig vikten av att nätverka',
    ActivityCategories.SokJobb,
  ],
  studies: ['Studera och öka din kompetens', ActivityCategories.Studera],
  matching: [
    'Matcha dig mot arbetsgivare',
    ActivityCategories.TraffaArbetsgivare,
  ],
  business: [
    'Företagande och entreprenörskap',
    ActivityCategories.StartaForetag,
  ],
  chef: [''],
  customer: [''],
  generic: [
    'Inspiration',
    'Satsa på din hälsa och motivation',
    'Planera din ekonomi',
    'Lyft fram dina styrkor',
    ActivityCategories.PersonligUtveckling,
  ],
}

export const getActivityImage = (
  imageCategory: ActivityImageCategoryKeys,
  slot: number,
  size: number
): string =>
  `${
    getConfig().MEDIA_URL
  }/globalassets/aktivitetsbilder/${imageCategory}/${slot}.jpg?width=${size}`
