import React, { SyntheticEvent, useCallback } from 'react'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { Button } from '@mui/material'

interface IOnlineMeetingButton {
  webLink: string
  customClass?: string
  testSelector?: string
  large?: boolean
}

const OnlineMeetingButton: React.FunctionComponent<IOnlineMeetingButton> = ({
  webLink,
  testSelector,
  large,
}) => {
  const t = useEpiCustomDictionary(['activity'])

  const buttonContent = webLink.includes('teams.microsoft')
    ? t('onlineMeetingTeams')
    : webLink.includes('zoom.us')
      ? t('onlineMeetingZoom')
      : t('onlineMeetingUnknown')

  const handleGoToMeeting = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      window.open(webLink, '_blank')
    },
    [webLink]
  )

  return (
    <Button
      size={large ? 'large' : 'small'}
      data-testid={testSelector}
      onClick={handleGoToMeeting}
    >
      {buttonContent}
    </Button>
  )
}

export default OnlineMeetingButton
