import { ActivityForm } from '@local/Activity/services/activity.types'

export interface IPagination {
  previousPage?: number
  currentPage?: number
  nextPage?: number
  totalPages?: number
}

export interface IFilteredActivityListItem {
  nextActivityRoundId: string
  activityForm: ActivityForm
  activityName: string
  nextActivityRoundStart: string
  nextActivityRoundTargetGroup: string
  preamble: string
  imageUrl: string
  areasOfInterest: string[]
  isOnline: boolean
  isOnlineAndPhysical: boolean
  nrOfUpcomingActivityRounds: number
  hasVacancies: boolean
  hasReserveVacancies: boolean
  cities?: string[]
}

export interface IGetFilteredActivitiesRequest {
  page?: number
  pageSize?: number
  cities?: string[]
  isOnline?: boolean
  areasOfInterest?: string[]
  targetGroups?: string[]
  activityForm?: string[]
  view?: View
}

export interface IGetFilteredActivitiesResponse {
  totalCount: number
  totalPages: number
  page: number
  items: IFilteredActivityListItem[]
  facets: Facet[]
}

export interface GetFacetsResponse {
  facets: Facet[]
}

export interface Facet {
  values: FacetValue[]
  field: string
}

export interface FacetValue {
  name: string
  count: number
}

export enum FacetValueTypes {
  NextActivityRoundTargetGroup = 'NextActivityRoundTargetGroup',
  Cities = 'Cities',
  IsOnline = 'IsOnline',
  ActivityForm = 'ActivityForm',
  AreasOfInterest = 'AreasOfInterest',
}

export enum View {
  Kund = 'Kund',
  Anstalld = 'Anstalld',
}

export type IGetActivityLocationsResponse = { name: string }[]

export type IGetAreasOfInterestResponse = { name: string }[]

export type IGetActivityTargetGroupsResponse = { name: string }[]
