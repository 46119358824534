const toDigits = (numString: string) =>
  numString.replace(/\D/g, '').split('').map(Number)

/**
 * Luhn check
 */
export const validateOrganizationNumber = (
  organizationNumber: string
): boolean => {
  if (!organizationNumber) {
    return false
  }
  const digits = toDigits(organizationNumber)
  const checkDigit = digits[digits.length - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map((current: number, index: number) =>
      index % 2 === 0 ? current * 2 : current
    )
    .map((current: number) => (current > 9 ? current - 9 : current))
    .reduce(
      (current: number, accumulator: number) => current + accumulator,
      checkDigit
    )
  return total % 10 === 0
}
