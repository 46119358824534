import React from 'react'
import MailAnsokanForm from '@local/Activity/Components/MailAnsokan/MailAnsokanForm/MailAnsokanForm'
import { IMailAnsokanApiModel } from '@local/Activity/Components/MailAnsokan/MailAnsokanTypes'
import { IPart } from '@local/Activity/services/activity.types'
import { useMailSignToActivityMutation } from '@local/Activity/services/mailSignup.service'
import { date } from '@trr/frontend-datetime'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { getTimespan } from '@local/Utils/Helpers/getTimespan'
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'

interface IMailAnsokanModal {
  activityName: string
  activityLocation: string
  activityStartPart: IPart
  handleClose: (success?: boolean) => void
}

const getPartTime = (part: IPart) =>
  `${date.format(part.start)} | ${getTimespan(part)}`

export const MailAnsokan: React.FunctionComponent<IMailAnsokanModal> = ({
  activityName,
  activityLocation,
  activityStartPart,
  handleClose,
}) => {
  const t = useEpiCustomDictionary(['activity'])
  const [triggerMailSign, mailSignResponse] = useMailSignToActivityMutation()
  const activityStartDate = activityStartPart.start

  const handleSubmit = (data: IMailAnsokanApiModel) => {
    void triggerMailSign({
      activityName,
      activityLocation,
      activityStartDate,
      ...data,
    })

    if (!mailSignResponse.isLoading && !mailSignResponse.isError) {
      handleClose(true)
    }
  }

  return (
    <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('formAnsokanTitle')}</DialogTitle>
      <DialogContent>
        <MailAnsokanForm
          handleSubmit={handleSubmit}
          errorOccurred={mailSignResponse.isError}
        >
          <Typography variant="h4" component="h3" gutterBottom>
            {activityName}
          </Typography>
          <Typography variant="h6" component="h4">
            {t('formAnsokanDateAndLocation')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom={false}>
            <p>{getPartTime(activityStartPart)}</p>
          </Typography>
          <Typography variant="subtitle2">
            <p>{activityLocation}</p>
          </Typography>
        </MailAnsokanForm>
      </DialogContent>
    </Dialog>
  )
}
