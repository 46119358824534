import { Pages } from '@local/App'
import { useCurrentKey, useEpiCustomDictionary } from '@trr/app-shell-data'
import React from 'react'
import { Link, Stack } from '@mui/material'

const SwitchViewLink: React.FunctionComponent = () => {
  const t = useEpiCustomDictionary(['activityList'])
  const currentKey = useCurrentKey()

  const recordedActivitiesLinkLabel =
    currentKey === Pages.HittaAktiviteterMedarbetare
      ? t('activitiesList.goToTjanstemanRecordedActivities')
      : t('activitiesList.goToKundRecordedActivities')

  const activitiesLinkLabel =
    currentKey === Pages.HittaAktiviteterMedarbetare
      ? t('activitiesList.switchToKundView')
      : t('activitiesList.switchToTjanstemanView')

  return (
    <Stack marginBottom={5} spacing={2}>
      <Link href={t('recordedActivitiesUrl')}>
        {recordedActivitiesLinkLabel}
      </Link>
      <Link href={t('switchViewUrl')}>{activitiesLinkLabel}</Link>
    </Stack>
  )
}

export default SwitchViewLink
