import { IMailAnsokanApiModel } from '@local/Activity/Components/MailAnsokan/MailAnsokanTypes'
import { validateOrganizationNumber } from '@local/Utils/Helpers/formHelper'
import { orgNummerRegex } from '@local/Utils/Helpers/regexes'
import * as Yup from 'yup'

export interface IMailAnsokanFormValues {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  organizationNumber: string
  organizationName: string
  role: string
  location: string
  notes: string
  approved: boolean
}

export const MailAnsokanFormSchema =
  (): Yup.AnySchema<IMailAnsokanFormValues> =>
    Yup.object().shape({
      firstName: Yup.string().required('Fältet får inte lämnas tomt'),
      lastName: Yup.string().required('Fältet får inte lämnas tomt'),
      organizationNumber: Yup.string()
        .required('Ange ett organisationsnummer')
        .matches(orgNummerRegex, 'Ange ett giltigt organisationsnummer')
        .test(
          'validateOrgnummer',
          'Ange ett giltigt organisationsnummer',
          (orgNummer) => validateOrganizationNumber(orgNummer)
        ),
      email: Yup.string()
        .email('Ange en giltig mejladress')
        .required('Ange din mejladress'),
      role: Yup.string().required('Fältet får inte lämnas tomt'),
      phoneNumber: Yup.string()
        .min(8, 'Ange ditt telefonnummer')
        .required('Ange ditt telefonnummer'),
      organizationName: Yup.string().required('Fältet får inte lämnas tomt'),
      location: Yup.string().required('Fältet får inte lämnas tomt'),
      notes: Yup.string(),
      approved: Yup.boolean().isTrue('Du måste godkänna villkoren'),
    })

export const mapAnsokanToArbetsplatsFormValues = (
  ansokan: IMailAnsokanApiModel
): IMailAnsokanFormValues => ({
  firstName: ansokan?.firstName,
  lastName: ansokan?.lastName,
  email: ansokan?.email,
  role: ansokan?.role,
  organizationNumber: ansokan?.organizationNumber,
  phoneNumber: ansokan?.phoneNumber,
  organizationName: ansokan?.organizationName,
  location: ansokan?.location,
  notes: ansokan?.notes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approved: (ansokan?.approved as any)?.includes('on') ? true : false,
})
