import React from 'react'
import ListCardWrapper from '@local/ActivitiesList/Components/ListCardWrapper/ListCardWrapper'
import VideoGrid from '@local/ActivitiesList/Components/VideoGrid'
import {
  useGetWebinarsWithMetaDataQuery,
  MAX_HITS,
} from '@local/WebinarsList/services/webinar.service'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { CircularProgress, Pagination, Stack } from '@mui/material'

interface VideoListProps {
  isCustomer: boolean
}

const VideoList: React.FunctionComponent<VideoListProps> = ({ isCustomer }) => {
  const {
    searchParams: { areasOfInterest: pickedAreasOfInterest, page },
    setSearchParamField,
  } = useSearchQueryParams()

  const { data: videoData, isLoading: isLoading } =
    useGetWebinarsWithMetaDataQuery({
      page: page,
      subjectCategories: pickedAreasOfInterest,
      isClient: !isCustomer,
    })

  const handlePaginationChange = (newPage: number) => {
    setSearchParamField(newPage.toString(), 'page')
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <ListCardWrapper>
        <VideoGrid webinarVideos={videoData?.webinars} />
      </ListCardWrapper>
      {videoData?.totalHits > MAX_HITS && (
        <Stack alignItems={'end'}>
          <Pagination
            onChange={(_, newPage) => handlePaginationChange(newPage)}
            count={Math.ceil(videoData.totalHits / MAX_HITS)}
            page={page}
            data-testid="videopagination"
          />
        </Stack>
      )}
    </>
  )
}

export default VideoList
