export const copyFormattedUrl = async (title: string, url: string) => {
  await navigator.clipboard.write([
    new ClipboardItem({
      'text/plain': new Blob([url], { type: 'text/plain' }),
      'text/html': new Blob([`<a href="${url}">${title}</a>`], {
        type: 'text/html',
      }),
    }),
  ])
}
