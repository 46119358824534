import { FilterCountIndicator } from '@local/ActivitiesList/Components/Filtering/Components/FilterCountIndicator'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  type SxProps,
  Typography,
  colors,
} from '@mui/material'
import React, { PropsWithChildren } from 'react'

interface FilterAccordionProps extends PropsWithChildren {
  title: string
  selectCount?: number
  defaultExpanded?: boolean
}

const AccordionStyle: SxProps = {
  boxShadow: 'none',
  borderTop: 'none',
  '::before': { display: 'none' },
  ':not(:last-child)': {
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}

export const FilterAccordion = ({
  title,
  selectCount = 0,
  defaultExpanded = true,
  children,
}: FilterAccordionProps) => (
  <Accordion
    sx={AccordionStyle}
    disableGutters
    defaultExpanded={defaultExpanded}
  >
    <AccordionSummary expandIcon={<ExpandMore />} aria-controls={title}>
      <Typography>{title}</Typography>
      {selectCount > 0 && (
        <FilterCountIndicator>{selectCount}</FilterCountIndicator>
      )}
    </AccordionSummary>
    <AccordionDetails sx={{ pl: 0.5, pr: 1 }}>{children}</AccordionDetails>
  </Accordion>
)
