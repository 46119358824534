// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.npzSqgYOM05d3fSZsVja{display:flex;flex-wrap:wrap;flex-direction:row;-moz-column-gap:32px;column-gap:32px;row-gap:16px;margin-bottom:unset}`, "",{"version":3,"sources":["webpack://./src/ActivitiesList/Components/ListCardWrapper/ListCardWrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,oBAAA,CAAA,eAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".ListWrapper {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  column-gap: 32px;\n  row-gap: 16px;\n  margin-bottom: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListWrapper": `npzSqgYOM05d3fSZsVja`
};
export default ___CSS_LOADER_EXPORT___;
