import {
  IGetFilteredActivitiesRequest,
  IGetFilteredActivitiesResponse,
} from '@local/ActivitiesList/services/activitiesList.types'
import { generateImage } from '@local/Utils/Helpers/ImageBank/imageHelpers'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'
import { createActivityRequestParams } from '@local/Utils/Helpers/apiHelpers'

export const activitiesListApi = createApi({
  reducerPath: 'activitiesListApi',
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getFilteredActivities: builder.query<
      IGetFilteredActivitiesResponse,
      {
        searchParams: IGetFilteredActivitiesRequest
        isCustomer: boolean
      }
    >({
      query: (input) => {
        const request = createActivityRequestParams(
          input.searchParams,
          input.isCustomer
        )
        const params = queryString.stringify(request, {
          arrayFormat: 'index',
        })

        return {
          url: 'aktivitetsbokningar/activities?' + params,
        }
      },
      transformResponse: (
        response: IGetFilteredActivitiesResponse,
        _,
        args
      ) => {
        const result = response?.items.map((i) => {
          if (i?.imageUrl) return i
          const randomNumber = i.nextActivityRoundId.replace(/\D/g, '')
          const slot = Number(randomNumber[randomNumber.length - 1])
          return {
            ...i,
            imageUrl: generateImage(
              slot,
              'small',
              i.areasOfInterest[0],
              args.isCustomer
            ),
          }
        })
        return { ...response, items: result }
      },
    }),
  }),
})

export const { useGetFilteredActivitiesQuery } = activitiesListApi
