import React from 'react'
import {
  LIFECYCLE_STATUS,
  REGISTRATION_AVAILABILITY,
} from '@local/Activity/ActivityStatuses'
import {
  ILifeCycleStatus,
  IPart,
  IRegistrationAvailabilityStatuses,
  ISchedule,
  TargetGroups,
} from '@local/Activity/services/activity.types'
import { useHistory } from 'react-router-dom'
import { getIcsFile } from '@local/Utils/Helpers/fileHelper'
import { isBefore } from 'date-fns'
import { find } from 'ramda'
import { goToLogin, goToLoginEmployer } from '@trr/app-shell-communication'
import { FromSourceTypes } from '@local/Types'
import { Chip } from '@mui/material'
import {
  signupSteps,
  useSearchQueryParams,
} from '@local/Utils/Hooks/useSearchQueryParams'
import { useAuthentication, useEpiCustomDictionary } from '@trr/app-shell-data'

const getNextUpComingWebLink = (parts: IPart[]): string =>
  find((part) => !isBefore(Date.parse(part.end.toString()), new Date()), parts)
    ?.remoteLink?.url

interface UseCtaStatusesProps {
  activityId: string
  activityName: string
  fromSource?: string
  hasReserveVacancies: boolean
  hasVacancies: boolean
  isCustomerUrl: boolean
  lifecycleStatus: ILifeCycleStatus
  registrationAvailability: IRegistrationAvailabilityStatuses
  schedule: ISchedule
  targetGroup?: string
}

export const useCtaStatuses = ({
  activityId,
  activityName,
  fromSource = '',
  hasReserveVacancies,
  hasVacancies,
  isCustomerUrl,
  lifecycleStatus,
  registrationAvailability,
  schedule,
  targetGroup = '',
}: UseCtaStatusesProps) => {
  const { start, parts } = schedule
  const history = useHistory()
  const t = useEpiCustomDictionary(['activity'])
  const { setSearchParamField } = useSearchQueryParams()
  const { userRoles: roles } = useAuthentication()
  const isCoworker = roles.includes('Medarbetare')

  const isNotLoggedIn =
    registrationAvailability === REGISTRATION_AVAILABILITY.LOGIN_REQUIRED
  const canRegister =
    registrationAvailability === REGISTRATION_AVAILABILITY.CAN_REGISTER
  const isFullyBooked =
    registrationAvailability === REGISTRATION_AVAILABILITY.FULLY_BOOKED
  const isReserve =
    registrationAvailability ===
    REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE
  const isRegistered =
    registrationAvailability === REGISTRATION_AVAILABILITY.ALREADY_REGISTERED
  const isCompleted = lifecycleStatus === LIFECYCLE_STATUS.COMPLETED
  const isCommencing = lifecycleStatus === LIFECYCLE_STATUS.COMMENCING
  const isOngoing = lifecycleStatus === LIFECYCLE_STATUS.ONGOING
  const noRegistrationRequired =
    registrationAvailability ===
    REGISTRATION_AVAILABILITY.NO_REGISTRATION_REQUIRED
  const isDormant = lifecycleStatus === LIFECYCLE_STATUS.DORMANT
  const requiresAdvisorApproval =
    registrationAvailability ===
    REGISTRATION_AVAILABILITY.REQUIRES_ADVISOR_APPROVAL
  const hasWrongRole =
    registrationAvailability === REGISTRATION_AVAILABILITY.USER_NOT_CORRECT_ROLE
  const hasPassed =
    REGISTRATION_AVAILABILITY.ACTIVITYROUND_PASSED === registrationAvailability
  const isFromCalendar = fromSource === FromSourceTypes.Calendar

  const ctaAreaRoundHasStarted = () => {
    if (isBefore(new Date(), Date.parse(start.toString()))) {
      return false
    }
    return isCommencing || isOngoing || isDormant
  }

  const ctaAreaRoundIsCommencing = () => isCommencing
  const ctaAreaRoundHasPassed = () => isCompleted
  const ctaAreaRoundHasStartedOrPassed = () =>
    ctaAreaRoundHasStarted() || ctaAreaRoundHasPassed()
  const ctaAreaRoundCanUnRegister = () => isRegistered || isReserve
  const ctaAreaRoundFullyBooked = () => !hasVacancies && !hasReserveVacancies
  const ctaAreaRoundRequiresAdvisorApproval = () => requiresAdvisorApproval
  const ctaAreaRoundUserHasWrongRoles = () =>
    hasWrongRole || (isNotLoggedIn && isCoworker)

  const ctaAreaTitleText = () => {
    if (isFromCalendar && noRegistrationRequired) {
      // if from calendar link, and not already registered in some way, assume that the user is registered
      if (isCommencing) {
        return t('LIFECYCLE_STATUS.COMMENCING')
      }
      if (noRegistrationRequired && !ctaAreaRoundHasStartedOrPassed()) {
        return t('REGISTRATION_AVAILABILITY.NO_REGISTRATION_REQUIRED') // "delta på aktiviteten"
      }
      if (!ctaAreaRoundHasStartedOrPassed()) {
        return t('REGISTRATION_AVAILABILITY.ALREADY_REGISTERED') // "anmäld"
      }
    }

    if (isRegistered) {
      if (isCommencing) {
        return t('LIFECYCLE_STATUS.COMMENCING')
      } else if (isOngoing) {
        return t('LIFECYCLE_STATUS.ONGOING')
      } else {
        return t('REGISTRATION_AVAILABILITY.ALREADY_REGISTERED')
      }
    }

    if (isReserve) {
      return t('REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE')
    }

    if (requiresAdvisorApproval || targetGroup === TargetGroups.Parter) {
      return (
        <Chip
          label={t('REGISTRATION_AVAILABILITY.REQUIRES_ADVISOR_APPROVAL')}
          variant="outlined"
        />
      )
    }

    if (!hasVacancies) {
      if (hasReserveVacancies) {
        return <Chip label={t('RESERVE_EXISTS')} variant="outlined" />
      } else {
        return (
          <Chip
            label={t('REGISTRATION_AVAILABILITY.FULLY_BOOKED')}
            color="error"
            variant="outlined"
          />
        )
      }
    }

    if (isNotLoggedIn) {
      if (isCoworker) {
        return t('REGISTRATION_AVAILABILITY.REGISTER')
      } else if (isCustomerUrl) {
        return t('REGISTRATION_AVAILABILITY.LOGIN_REQUIRED.CUSTOMER')
      } else {
        return t('REGISTRATION_AVAILABILITY.LOGIN_REQUIRED.CLIENT')
      }
    }

    if (hasWrongRole) {
      return t('REGISTRATION_AVAILABILITY.REGISTER')
    }
    if (isCompleted) {
      return t('LIFECYCLE_STATUS.COMPLETED')
    }
    if (isDormant) {
      return t('LIFECYCLE_STATUS.DORMANT')
    }
    if (isOngoing) {
      return t('LIFECYCLE_STATUS.ONGOING')
    }
    if (isCommencing) {
      return t('LIFECYCLE_STATUS.COMMENCING')
    }
    if (noRegistrationRequired) {
      return t('REGISTRATION_AVAILABILITY.NO_REGISTRATION_REQUIRED')
    }

    return t('REGISTRATION_AVAILABILITY.REGISTER')
  }

  const ctaAreaPrimaryButtonStates = (): [string, () => void] => {
    if (targetGroup === TargetGroups.Parter) {
      return ['', null]
    }
    if (
      isFromCalendar &&
      noRegistrationRequired &&
      !isCommencing &&
      !getNextUpComingWebLink(parts)
    ) {
      return ['', null]
    }
    if (isNotLoggedIn && !hasVacancies && !hasReserveVacancies) {
      return ['', null]
    }
    if (hasWrongRole) {
      return ['', null]
    }
    // similar to hasWrongRole, but for coworkers without access
    if (isNotLoggedIn && isCoworker) {
      return ['', null]
    }
    if (isNotLoggedIn) {
      // todo: change isCustomer login flow to the correct function when it exists
      return isCustomerUrl
        ? [
            t('loginFlowCustomer'),
            () => goToLoginEmployer(`${location.pathname}${location.search}`),
          ]
        : [
            t('loginFlowClient'),
            () => goToLogin(`${location.pathname}${location.search}`),
          ]
    }
    if (isCompleted) {
      return [
        t('registrationFlowExitButtonText'),
        () => history.push(t('findActivitiesUrl')),
      ]
    }
    if (isReserve) {
      return [
        t('REGISTRATION_AVAILABILITY.UNREGISTER'),
        () => setSearchParamField(signupSteps.Action, 'step'),
      ]
    }
    if (isDormant && !isFromCalendar) {
      return [
        t('registrationFlowDownLoadCalendar'),
        () => getIcsFile(activityId, activityName),
      ]
    }
    if (
      (isCommencing && ctaAreaRoundCanUnRegister()) ||
      (isCommencing && hasPassed) ||
      isOngoing ||
      noRegistrationRequired
    ) {
      if (getNextUpComingWebLink(parts)) {
        return [
          getNextUpComingWebLink(parts)?.includes('teams.microsoft')
            ? t('onlineMeetingTeams')
            : getNextUpComingWebLink(parts)?.includes('zoom.us')
              ? t('onlineMeetingZoom')
              : t('onlineMeetingUnknown'),
          () => window.open(getNextUpComingWebLink(parts), '_blank'),
        ]
      } else if (!isFromCalendar && !canRegister) {
        return [
          t('registrationFlowDownLoadCalendar'),
          () => getIcsFile(activityId, activityName),
        ]
      }
    }

    if (isRegistered && !isFromCalendar) {
      return [
        t('registrationFlowDownLoadCalendar'),
        () => getIcsFile(activityId, activityName),
      ]
    }

    if (isFullyBooked) {
      return ['', null]
    }
    if (requiresAdvisorApproval) {
      return ['', null]
    }
    if (!isRegistered) {
      return [
        t('callToActionPrimaryButtonText'),
        () => setSearchParamField(signupSteps.Action, 'step'),
      ]
    }

    return ['', null]
  }

  const ctaAreaSecondaryButtonStates = (): [string, () => void] => {
    if (isRegistered && !isOngoing && !ctaAreaRoundHasPassed()) {
      return [
        t('REGISTRATION_AVAILABILITY.UNREGISTER'),
        () => setSearchParamField(signupSteps.Action, 'step'),
      ]
    }

    if (
      noRegistrationRequired &&
      !isFromCalendar &&
      ctaAreaRoundIsCommencing()
    ) {
      return [
        t('registrationFlowDownLoadCalendar'),
        () => getIcsFile(activityId, activityName),
      ]
    }
    return ['', null]
  }

  return {
    ctaAreaTitleText: ctaAreaTitleText(),
    ctaAreaPrimaryButtonStates: ctaAreaPrimaryButtonStates(),
    ctaAreaSecondaryButtonStates: ctaAreaSecondaryButtonStates(),
    ctaAreaRoundHasStarted: ctaAreaRoundHasStarted(),
    ctaAreaRoundHasPassed: ctaAreaRoundHasPassed(),
    ctaAreaRoundHasStartedOrPassed: ctaAreaRoundHasStartedOrPassed(),
    ctaAreaRoundCanUnRegister: ctaAreaRoundCanUnRegister(),
    ctaAreaRoundFullyBooked: ctaAreaRoundFullyBooked(),
    ctaAreaRoundRequiresAdvisorApproval: ctaAreaRoundRequiresAdvisorApproval(),
    ctaAreaRoundUserHasWrongRoles: ctaAreaRoundUserHasWrongRoles(),
  }
}
