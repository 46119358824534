import { IVideo } from '@local/ActivitiesList/Components/VideoGrid/VideoGrid'
import { sortAreasOfInterest } from '@local/ActivitiesList/services/activitiesList.service.helpers'
import {
  IRecordedActivitiesApiResponse,
  IEpiVideo,
  IYoutubeResponse,
  WebinarResponse,
} from '@local/WebinarsList/services/webinar.types'
import getConfig from '@local/Utils/ConfigService/getConfig'
import { parseYoutubeId } from '@local/Utils/Helpers/parseYoutubeId'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import {
  createApi,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { flatten, join, map, pluck, prop, uniq } from 'ramda'
import queryString from 'query-string'

export const MAX_HITS = 15 as const

export const webinarsApi = createApi({
  reducerPath: 'webinarsApi',
  baseQuery: trrFetchBaseQuery(),
  tagTypes: ['Webinars'],
  endpoints: (builder) => ({
    // Get activities with metadata
    getWebinarsWithMetaData: builder.query<
      IRecordedActivitiesApiResponse,
      {
        page?: number
        pageSize?: number
        subjectCategories?: string[]
        isClient?: boolean
      }
    >({
      queryFn: async (
        {
          page = 1,
          pageSize = MAX_HITS,
          subjectCategories = '',
          isClient = false,
        },
        _queryApi,
        _extraOptions,
        fetchWithMetaData
      ) => {
        const epiRequest = {
          skip: pageSize * (page - 1),
          take: pageSize,
          subjectCategory: subjectCategories,
          isClient,
        }
        const epiParams = queryString.stringify(epiRequest)
        const fetchVideos = await fetchWithMetaData(
          `content/webinars?${epiParams}`
        )
        const videos = fetchVideos.data as IRecordedActivitiesApiResponse

        if (videos.webinars.length === 0) {
          return {
            error: {
              status: 'FETCH_ERROR',
            } as FetchBaseQueryError,
          }
        }

        try {
          const fetchVideosMetaData = (await fetch(
            `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,snippet&id=${join(
              ',',
              map(
                (item: IEpiVideo) => parseYoutubeId(prop('videoUrl', item)),
                videos.webinars
              )
            )}&key=${getConfig().YOUTUBE_API_KEY}`
          ).then((response) => response.json())) as IYoutubeResponse

          if (fetchVideosMetaData.items.length !== videos.webinars.length) {
            console.error('Some Youtube videos are unavailable/broken.')
          }

          return {
            data: {
              ...videos,
              webinars: {
                ...fetchVideosMetaData.items.map((item) => ({
                  duration: item?.contentDetails?.duration,
                  thumbnails: item?.snippet.thumbnails,
                  id: item?.id,
                  ...videos.webinars.find(
                    (w) => item?.id === parseYoutubeId(w.videoUrl)
                  ),
                })),
              },
            },
          }
        } catch (error) {
          return {
            data: {
              ...videos,
            },
          }
        }
      },
      providesTags: (_result, _error, arg) => [
        {
          type: 'Webinars',
          id: `${arg?.page}${arg?.isClient}${arg.subjectCategories?.join(',')}`,
        },
      ],
    }),
    getWebinarCategories: builder.query<
      WebinarResponse[],
      { isClient: boolean }
    >({
      query: (input) => `content/webinars?take=999&isClient=${input.isClient}`,
      transformResponse: (response: { webinars: IEpiVideo[] }) => {
        const sortedCategories = map(
          (cat) => ({
            label: cat,
            value: cat,
            count: response.webinars.filter((webinar) =>
              webinar.subjectCategories.includes(cat)
            )?.length,
          }),
          uniq(flatten(pluck('subjectCategories', response.webinars)))
        ).sort(sortAreasOfInterest)

        return sortedCategories
      },
    }),
    getCustomerWebinars: builder.query<IVideo[], string[]>({
      queryFn: async (videoIds) => {
        try {
          const fetchVideosMetaData = (await fetch(
            `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,snippet&id=${join(
              ',',
              videoIds
            )}&key=${getConfig().YOUTUBE_API_KEY}`
          ).then((response) => response.json())) as IYoutubeResponse

          const webinars: IVideo[] = fetchVideosMetaData.items.map((video) => ({
            body: video?.snippet?.description,
            heading: video?.snippet?.localized.title,
            broadcastDate: video.snippet.publishedAt,
            duration: video?.contentDetails?.duration,
            thumbnails: video?.snippet.thumbnails,
            id: video?.id,
          }))
          return { data: webinars }
        } catch (error) {
          const noVideos: IVideo[] = []
          return {
            data: noVideos,
          }
        }
      },
    }),
  }),
})

export const {
  useGetCustomerWebinarsQuery,
  useGetWebinarCategoriesQuery,
  useGetWebinarsWithMetaDataQuery,
} = webinarsApi
