import { IMailSignParameters } from '@local/Activity/services/mailSignup.types'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'

export const mailSignupApi = createApi({
  reducerPath: 'mailSignupApi',
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    mailSignToActivity: builder.mutation<void, IMailSignParameters>({
      query: (activity) => ({
        url: `/CustomerActivity`,
        method: 'POST',
        body: activity,
      }),
    }),
  }),
})

export const { useMailSignToActivityMutation } = mailSignupApi
