import {
  LIFECYCLE_STATUS,
  REGISTRATION_AVAILABILITY,
} from '@local/Activity/ActivityStatuses'
import { ValueOf } from '@local/App.types'

export interface IPreparation {
  header: string
  description: string
  timeRequiredInMinutes: number
}

export interface IMaterial {
  hasMaterial: boolean
  links: IMaterialLinks[]
}

export interface IMaterialLinks {
  description: string
  url: string
  contentType: string
}

export type IRegistrationAvailabilityStatuses = ValueOf<
  typeof REGISTRATION_AVAILABILITY
>

export type ILifeCycleStatus = ValueOf<typeof LIFECYCLE_STATUS>

export enum ActivityForm {
  Workshop = 'Workshop',
  Parter = 'Partsträff',
  Program = 'Program',
  Speedinterview = 'Speedintervju',
  Webinar = 'Webbinarium',
  Event = 'Event',
}

export interface IAdress {
  street: string
  city: string
}

export interface IPart {
  physicalAddress: IAdress
  remoteLink: {
    description: string
    url: string
  }
  start: string
  end: string
}

export interface ISchedule {
  parts: IPart[]
  start: string
  end: string
  lifecycleStatus: ILifeCycleStatus
}

export enum TargetGroups {
  Parter = 'parter',
}

export interface ITargetAudience {
  location: string[]
  targetGroup: TargetGroups | string
}

export interface IRegistrationAvailability {
  registrationAvailability: IRegistrationAvailabilityStatuses
  vacancies: number
  hasVacancies: boolean
  hasReserveVacancies: boolean
  lastRegistrationDate: string
  capacity: number
}

export interface IRelatedActivityRound {
  activityRoundId: string
  address: IAdress
  start: string
  end: string
  location: string[]
  areasOfInterest: string[]
  registrationAvailability: IRegistrationAvailabilityStatuses
}

export interface IActivityResponse {
  id: string
  activity: Activity
  schedule: ISchedule
  targetAudience: ITargetAudience
  registration: IRegistrationAvailability
  preparation: IPreparation
  workMaterial: IMaterial
  relatedActivityRounds: IRelatedActivityRound[]
  administrativeInformation: { note: string }
  isOnline: boolean
}

export interface Activity {
  name: string
  preamble: string
  form: ActivityForm
  description: string
  imageUrl: string
  areasOfInterest: string[]
}
