import { Grid } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export const ActivityGrid = ({ children, ...props }: PropsWithChildren) => (
  <Grid
    container
    spacing={{ xs: 1, sm: 2, md: 3 }}
    columns={{ xs: 1, sm: 2, md: 3 }}
    mb={2}
    {...props}
  >
    {children}
  </Grid>
)
