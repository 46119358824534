import React, { useMemo } from 'react'
import OccasionRadioButton from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionOccasions/OccasionRadioButton'
import { IActivityResponse } from '@local/Activity/services/activity.types'
import { IMedarbetareActivityResponse } from '@local/Medarbetare/Services/medarbetareActivity.types'
import {
  getRadioButtonAdress,
  getRadioButtonDate,
} from '@local/Utils/Helpers/activity.helpers'
import { useHistory } from 'react-router-dom'
import { RadioGroup } from '@mui/material'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'

interface RadioButtonOptions {
  primary: string
  secondary: string
  id: string
}

interface OccasionRadioButtonGroupProps {
  activity: IActivityResponse | IMedarbetareActivityResponse
}

const OccasionRadioButtonGroup: React.FunctionComponent<
  OccasionRadioButtonGroupProps
> = ({ activity }) => {
  const history = useHistory()

  const {
    searchParams: { id: currentOccasionId },
  } = useSearchQueryParams()

  const { relatedActivityRounds } = activity

  const handleOnChange = (id: string) => {
    const newUrl: string[] = history.location.pathname.split('/')
    // if trailing slash, pop twice. Once to remove it and then to remove the id. Otherwise just remove the id
    newUrl.pop() || newUrl.pop()
    history.replace({
      pathname: `${newUrl.join('/')}/${id}`,
      search: history.location.search,
    })
  }

  const radioButtonList: RadioButtonOptions[] = useMemo(
    () => {
      const optionList = [
        ...relatedActivityRounds.map((x) => ({
          start: x.start,
          end: x.end,
          address: x.address,
          id: x.activityRoundId,
        })),
        {
          start: activity.schedule.parts[0].start,
          end: activity.schedule.parts[0].end,
          address: activity.schedule.parts[0].physicalAddress,
          id: activity.id,
        },
      ]

      const sortedOptionList = optionList.sort((a, b) =>
        a.start > b.start ? 1 : -1
      )

      const radioButtonOptionsList = sortedOptionList.map((y) => ({
        primary: getRadioButtonDate({
          start: y.start,
          end: y.end,
        }),
        secondary: getRadioButtonAdress(y.address),
        id: y.id,
      }))

      return radioButtonOptionsList
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <RadioGroup>
      {radioButtonList.map((occasion) => (
        <OccasionRadioButton
          key={occasion.id}
          checked={occasion.id === currentOccasionId}
          primaryText={occasion.primary}
          secondaryText={occasion.secondary}
          id={occasion.id}
          onChange={handleOnChange}
        />
      ))}
    </RadioGroup>
  )
}

export default OccasionRadioButtonGroup
