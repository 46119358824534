import React from 'react'
import EpiParser from '@local/Activity/Components/Layout/EpiParser'
import { useEpiContent } from '@trr/app-shell-data'
import { AktivitetsbokningarContent } from '@local/Types'

export const ClosingStatement = () => {
  const { closingStatement } = useEpiContent<AktivitetsbokningarContent>()

  if (!closingStatement) {
    return
  }

  return <EpiParser body={closingStatement.mainBody} />
}
