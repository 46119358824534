import React from 'react'
import { FormControlLabel, Link, ListItemText, Radio } from '@mui/material'
import { capitalize } from '@local/Utils/Helpers/stringHelper'

interface OccasionRadioButtonProps {
  checked?: boolean
  primaryText: string
  secondaryText: string
  id: string
  onChange: (id: string) => void
}

const OccasionRadioButton: React.FunctionComponent<
  OccasionRadioButtonProps
> = ({ checked, primaryText, secondaryText, id, onChange }) => {
  const handleOnChange = () => {
    onChange(id)
  }

  const splitSecondaryText = secondaryText.split('\n')

  return (
    <FormControlLabel
      value={id}
      name="occasion"
      onChange={handleOnChange}
      checked={checked}
      control={<Radio color="primary" />}
      sx={{ alignItems: 'flex-start' }}
      label={
        <ListItemText
          primary={primaryText}
          secondary={
            secondaryText === 'Online' ? (
              <>
                {splitSecondaryText[0]}
                {splitSecondaryText.length > 1 && (
                  <>
                    <br />
                    {capitalize(splitSecondaryText[1])}
                  </>
                )}
              </>
            ) : (
              <Link
                href={`https://maps.google.com?q=${
                  splitSecondaryText[0] + ', ' + splitSecondaryText[1]
                }`}
                target="_blank"
              >
                {splitSecondaryText[0]}
                {splitSecondaryText.length > 1 && (
                  <>
                    <br />
                    {capitalize(splitSecondaryText[1])}
                  </>
                )}
              </Link>
            )
          }
          sx={{ display: 'inline-grid', ml: 1 }}
        />
      }
      data-testid="occasion-radio-button"
    />
  )
}

export default OccasionRadioButton
