import {
  ISignOnOrOffResponse,
  ISignParameters,
} from '@local/Activity/services/signup.types'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react'

export const RegistrationTypes = {
  register: 'register',
  unregister: 'unregister',
} as const

export const signupApi = createApi({
  reducerPath: 'signupApi',
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    signToActivity: builder.query<ISignOnOrOffResponse, ISignParameters>({
      query: ({ id, type, userId }) => {
        const registerOrUnregister =
          type === RegistrationTypes.register ? type : `${type}/user/${userId}`
        const registerOrUnregisterPayload =
          type === RegistrationTypes.register
            ? {
                activityRoundId: id,
              }
            : {
                activityRoundIds: [id],
              }
        return {
          url: `/aktivitetsbokningar/ActivityRounds/${registerOrUnregister}`,
          method: 'POST',
          body: registerOrUnregisterPayload,
        }
      },
    }),
  }),
})

export const { useLazySignToActivityQuery } = signupApi
