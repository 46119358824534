import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import EpiParser from '@local/Activity/Components/Layout/EpiParser'
import PositivismIllustration from '@local/Activity/Components/Layout/Illustrations/PositivismIllustration.illustration'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { useGetActivityQuery } from '@local/Activity/services/activity.service'
import { REGISTRATION_AVAILABILITY } from '@local/Activity/ActivityStatuses'
import { getIcsFile } from '@local/Utils/Helpers/fileHelper'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import {
  Box,
  Button,
  Card,
  CardContent,
  ListItemText,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import {
  getAddress,
  getRadioButtonDate,
} from '@local/Utils/Helpers/activity.helpers'
import Monitor from '@mui/icons-material/Monitor'
import FmdGood from '@mui/icons-material/FmdGood'
import { getStageLocation } from '@local/Utils/Helpers/getLocation'

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  maxWidth: '650px',
  minHeight: '350px',
  margin: '0 auto',
})

const ButtonWrapper = styled(Box)<{ $isdesktop: boolean }>(
  ({ $isdesktop }) => ({
    display: 'flex',
    flexDirection: $isdesktop ? 'row' : 'column',
    gap: '.5rem',
    marginTop: '2rem',
  })
)

const CompletedStepButton = styled(Button)({
  marginBottom: '1rem',
})

const IllustrationWrapper = styled(Box)<{
  $isdesktop: boolean
}>(({ $isdesktop }) => ({
  marginBottom: '2rem',
  height: $isdesktop ? '280px' : '160px',
  width: $isdesktop ? '320px' : '180px',
  margin: '0 auto',
}))

export const CompletedStep = () => {
  const t = useEpiCustomDictionary(['activity'])
  const history = useHistory()
  const { isCustomer } = useUserContext()

  const {
    searchParams: { id },
  } = useSearchQueryParams()
  const {
    data: {
      registration: { registrationAvailability },
      activity: { name },
      schedule,
      isOnline,
    },
    refetch,
    requestId,
    isFetching,
  } = useGetActivityQuery({ id, isCustomer }, { skip: id === '' })
  const [initialRequestId] = useState<string>(requestId)
  const [previousAndCurrentStatus, setPreviousAndCurrentStatus] = useState<{
    from: string | null
    to: string | null
  }>({ from: registrationAvailability, to: null })

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (requestId === initialRequestId) {
      void refetch()
    }
    if (
      requestId !== initialRequestId &&
      isFetching === false &&
      previousAndCurrentStatus.to === null
    ) {
      setPreviousAndCurrentStatus((prev) => ({
        from: prev.from,
        to: registrationAvailability,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, isFetching])

  useEffect(() => {
    const backButton: HTMLElement = document.querySelector(
      "button[class^='Breadcrumbs_InternalBackLink']"
    )
    if (backButton) {
      backButton.style.display = 'none'
    }

    return () => {
      if (backButton) {
        backButton.style.display = 'initial'
      }
      setPreviousAndCurrentStatus(null)
    }
  }, [])

  const handleSecondaryClick = useCallback(() => {
    history.push(t('findActivitiesUrl'))
  }, [history, t])

  const handlePrimaryClick = useCallback(() => {
    id && name && void getIcsFile(id, name)
  }, [id, name])

  const handleReserveSecondaryClick = useCallback(() => {
    history.push(t('reservefindActivitiesUrl'))
  }, [history, t])

  const handleReservePrimaryClick = useCallback(() => {
    history.push(t('findActivitiesUrl'))
  }, [history, t])

  if (!previousAndCurrentStatus.to) {
    return null
  }

  const dateAndTime = getRadioButtonDate({
    start: schedule.parts[0].start,
    end: schedule.parts[0].end,
  })

  if (
    (previousAndCurrentStatus.from === REGISTRATION_AVAILABILITY.CAN_REGISTER &&
      previousAndCurrentStatus.to ===
        REGISTRATION_AVAILABILITY.ALREADY_REGISTERED) ||
    (previousAndCurrentStatus.from ===
      REGISTRATION_AVAILABILITY.CAN_REGISTER_AS_RESERVE &&
      previousAndCurrentStatus.to ===
        REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE)
  ) {
    return (
      <Wrapper data-testid="completedStep">
        <IllustrationWrapper $isdesktop={isDesktop}>
          <PositivismIllustration />
        </IllustrationWrapper>
        <Typography variant="h2" component="h1" gutterBottom>
          {previousAndCurrentStatus.from ===
          REGISTRATION_AVAILABILITY.CAN_REGISTER_AS_RESERVE
            ? t('registrationReserveCompleteModalTitle')
            : t('registrationCompleteModalTitle')}
        </Typography>
        <EpiParser
          body={
            previousAndCurrentStatus.from ===
            REGISTRATION_AVAILABILITY.CAN_REGISTER_AS_RESERVE
              ? t('registrationReserveCompleteModalText')
              : t('registrationCompleteModalText')
          }
        />
        {previousAndCurrentStatus.from ===
          REGISTRATION_AVAILABILITY.CAN_REGISTER_AS_RESERVE && (
          <Card sx={{ marginTop: 1 }}>
            <CardContent sx={{ textAlign: 'left' }}>
              <Typography variant="h5">{name}</Typography>
              <CardListItem>
                <CardListItemIcon alignTop>
                  {isOnline ? <Monitor /> : <FmdGood />}
                </CardListItemIcon>
                <ListItemText
                  primary={dateAndTime}
                  secondary={
                    isOnline ? (
                      <>{getAddress(schedule.parts[0].physicalAddress)}</>
                    ) : (
                      <Link
                        href={`https://maps.google.com/?q=${getAddress(
                          schedule.parts[0].physicalAddress
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getStageLocation(isOnline, schedule.parts[0])}
                      </Link>
                    )
                  }
                />
              </CardListItem>
            </CardContent>
          </Card>
        )}
        {previousAndCurrentStatus.from ===
        REGISTRATION_AVAILABILITY.CAN_REGISTER_AS_RESERVE ? (
          <ButtonWrapper $isdesktop={isDesktop}>
            <CompletedStepButton
              variant="outlined"
              size="large"
              onClick={handleReserveSecondaryClick}
            >
              {t('reserveSecondaryClickText')}
            </CompletedStepButton>
            <CompletedStepButton
              size="large"
              onClick={handleReservePrimaryClick}
            >
              {t('reservePrimaryClickText')}
            </CompletedStepButton>
          </ButtonWrapper>
        ) : (
          <>
            <ButtonWrapper $isdesktop={isDesktop}>
              <CompletedStepButton
                variant="outlined"
                size="large"
                onClick={handleSecondaryClick}
              >
                {t('secondaryClickText')}
              </CompletedStepButton>
              <CompletedStepButton size="large" onClick={handlePrimaryClick}>
                {t('primaryClickText')}
              </CompletedStepButton>
            </ButtonWrapper>
            <EpiParser body={t('registrationFlowDownLoadCalendarHelpInfo')} />
          </>
        )}
      </Wrapper>
    )
  }

  return (
    <Wrapper data-testid="completedStep">
      <Typography variant="h2" component="h1" gutterBottom>
        {t('registrationFlowUnregisterHeader')}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t('registrationFlowUnregisterText')}
      </Typography>
      <ButtonWrapper $isdesktop={isDesktop}>
        <CompletedStepButton size="large" onClick={handleSecondaryClick}>
          {t('registrationFlowExitButtonText')}
        </CompletedStepButton>
      </ButtonWrapper>
    </Wrapper>
  )
}
