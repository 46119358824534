import { trimTrailingSlash } from '@local/Utils/Helpers/stringHelper'
import { API_URL } from '@local/Utils/Network'
import { getIdToken } from '@trr/app-shell-data'

export const downloadFile = (
  blob: Blob,
  filename: string,
  fileExt: string
): boolean => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  try {
    a.href = url
    a.target = '_blank'
    a.download = `${filename}.${fileExt}`
    document.body.appendChild(a)
    a.click()
    a.remove()
    return true
  } catch (error) {
    return false
  }
}

export const getFile = async (
  url: RequestInfo,
  fileName: string,
  fileExt: string
) => {
  const options = {
    method: 'GET',
    headers: new Headers({
      authorization: `Bearer ${getIdToken()}`,
      'Accept-Language': 'sv',
    }),
  }

  const data = await fetch(url, options)
  const blob = await data.blob()
  return downloadFile(blob, fileName, fileExt)
}

export const getIcsFile = (id: string, name: string) =>
  getFile(
    `${trimTrailingSlash(
      API_URL
    )}/aktivitetsbokningar/ActivityRounds/${id}/invitation`,
    name,
    'ics'
  )
