import React from 'react'
import { FilterAccordion } from '@local/ActivitiesList/Components/Filtering/Components/FilterAccordion'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { ActivityformFilterList } from '@local/ActivitiesList/Components/Filtering/ActivityformFilter/ActivityformFilterList'

export const ExpandingActivityformFilter = () => {
  const {
    searchParams: { activityForm },
  } = useSearchQueryParams()

  return (
    <FilterAccordion title="Platser" selectCount={activityForm?.length}>
      <ActivityformFilterList />
    </FilterAccordion>
  )
}
