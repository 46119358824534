import React from 'react'
import { ChipPopover } from '@local/ActivitiesList/Components/Filtering/ChipPopover/ChipPopover'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { TargetGroupFilterList } from '@local/ActivitiesList/Components/Filtering/TargetGroupFilter/TargetGroupFilterList'

export const ChipTargetGroupFilter = () => {
  const {
    searchParams: { targetGroups },
    unsetSearchParamField,
  } = useSearchQueryParams()

  const handleReset = () => {
    unsetSearchParamField('targetGroups')
  }

  return (
    <ChipPopover
      label="Målgrupper"
      selectCount={targetGroups?.length}
      chipTestId="listFilterTargetGroupsChip"
      handleReset={handleReset}
    >
      <TargetGroupFilterList />
    </ChipPopover>
  )
}
