import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'

interface ActivityImageProps {
  url: string
  alt: string
}

export const ActivityImage = ({ url, alt }: ActivityImageProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      component="img"
      src={url}
      alt={alt}
      sx={{
        width: '100%',
        objectFit: 'cover',
        backgroundColor: 'white',
        borderRadius: '4px',
        maxHeight: '528px',
        height: '100%',
        display: isDesktop ? 'block' : 'none',
        marginBottom: 3,
      }}
    />
  )
}
