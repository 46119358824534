import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Stack } from '@mui/material'

interface ArticleProps extends PropsWithChildren {
  dataTestId?: string
}

export const Article: FunctionComponent<ArticleProps> = (props) => (
  <Stack component="article" maxWidth={'sm'} data-testid={props.dataTestId}>
    {props.children}
  </Stack>
)
