import React from 'react'
import { ChipAreasOfInterestFilter } from '@local/ActivitiesList/Components/Filtering/AreasOfInterestFilter/ChipAreasOfInterestFilter'
import { ExpandingAreasOfInterestFilter } from '@local/ActivitiesList/Components/Filtering/AreasOfInterestFilter/ExpandingAreasOfInterestFilter'
import { FilterWrapper } from '@local/ActivitiesList/Components/Filtering/Components/FilterWrapper'
import { ChipLocationFilter } from '@local/ActivitiesList/Components/Filtering/LocationFilter/ChipLocationFilter'
import { ExpandingLocationFilter } from '@local/ActivitiesList/Components/Filtering/LocationFilter/ExpandingLocationFilter'
import { MobileFilterBarDrawer } from '@local/ActivitiesList/Components/Filtering/MobileFilterBarDrawer/MobileFilterBarDrawer'
import { ChipTargetGroupFilter } from '@local/ActivitiesList/Components/Filtering/TargetGroupFilter/ChipTargetGroupFilter'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { ExpandingTargetGroupFilter } from '@local/ActivitiesList/Components/Filtering/TargetGroupFilter/ExpandingTargetGroupFilter'
import { useMediaQuery, useTheme } from '@mui/material'
import { ExpandingActivityformFilter } from '@local/ActivitiesList/Components/Filtering/ActivityformFilter/ExpandingActivityformFilter'
import { ChipActivityformFilter } from '@local/ActivitiesList/Components/Filtering/ActivityformFilter/ChipActivityformFilter'
import { RecordedExpandingAreasOfInterestFilter } from '@local/ActivitiesList/Components/Filtering/RecordedAreasOfInterestFilter/RecordedExpandingAreasOfInterestFilter'
import { RecordedChipAreasOfInterestFilter } from '@local/ActivitiesList/Components/Filtering/RecordedAreasOfInterestFilter/RecordedChipAreasOfInterestFilter'

interface FiltersProps {
  showLocationFilter?: boolean
  showAreasOfInterestFilter?: boolean
  showTargetGroupFilter?: boolean
  showRecordedAreasOfInterestFilter?: boolean
  showActivityFormFilter?: boolean
}

export const Filters = ({
  showLocationFilter,
  showAreasOfInterestFilter,
  showTargetGroupFilter,
  showRecordedAreasOfInterestFilter,
  showActivityFormFilter = true,
}: FiltersProps) => {
  const {
    searchParams: {
      areasOfInterest,
      cities,
      isOnline,
      targetGroups,
      activityForm,
    },
    clearFilters,
  } = useSearchQueryParams()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const totalCount =
    activityForm?.length +
    areasOfInterest?.length +
    targetGroups?.length +
    cities?.length +
    (isOnline ? 1 : 0)

  const isSearchFilterActive = totalCount > 0

  return isMobile ? (
    <MobileFilterBarDrawer
      title="Alla filter"
      activeFilterCount={totalCount}
      resetAllFilters={clearFilters}
    >
      {showAreasOfInterestFilter && <ExpandingAreasOfInterestFilter />}
      {showTargetGroupFilter && <ExpandingTargetGroupFilter />}
      {showLocationFilter && <ExpandingLocationFilter />}
      <ExpandingActivityformFilter />
      {showRecordedAreasOfInterestFilter && (
        <RecordedExpandingAreasOfInterestFilter />
      )}
    </MobileFilterBarDrawer>
  ) : (
    <FilterWrapper
      showResetFilters={isSearchFilterActive}
      resetFilters={clearFilters}
    >
      {showLocationFilter && <ChipLocationFilter />}
      {showAreasOfInterestFilter && <ChipAreasOfInterestFilter />}
      {showTargetGroupFilter && <ChipTargetGroupFilter />}
      {showActivityFormFilter && <ChipActivityformFilter />}
      {showRecordedAreasOfInterestFilter && (
        <RecordedChipAreasOfInterestFilter />
      )}
    </FilterWrapper>
  )
}
