import { IActivityResponse } from '@local/Activity/services/activity.types'

export enum Tags {
  HIDDEN = 'HIDDEN',
  FULLY_BOOKED = 'FULLY_BOOKED',
  CANCELLED = 'CANCELLED',
  ONLY_RESERVE_VACANCIES_AVAILABLE = 'ONLY_RESERVE_VACANCIES_AVAILABLE',
  HAS_PROJECT = 'HAS_PROJECT',
  REQUIRES_ADVISOR_APPROVAL = 'REQUIRES_ADVISOR_APPROVAL',
  LAST_REGISTRATION_DATE_PASSED = 'LAST_REGISTRATION_DATE_PASSED',
}

export interface IMedarbetareActivityResponse extends IActivityResponse {
  tags: Tags[]
  administrativeInformation: {
    note: string
  }
}
