import React from 'react'
import { Provider } from 'react-redux'
import { AppShellRouter, KeyRoute } from '@local/Utils/Router'
import { Activity } from '@local/Activity'
import { ActivitiesList } from '@local/ActivitiesList'
import store from '@local/Store/configureStore'
import RegisteredActivitiesList from '@local/UserActivities/RegisteredActivities'
import AttendedActivitiesList from '@local/UserActivities/AttendedActivitiesList'
import { UserContextProvider } from '@local/Utils/Hooks/useUserContext'
import { MedarbetareActivitiesList } from '@local/Medarbetare/MedarbetareActivitiesList/MedarbetareActivitiesList'
import { MedarbetareActivity } from '@local/Medarbetare/MedarbetareActivity/MedarbetareActivity'
import { ThemeProvider, createTheme } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { QueryParamProvider } from 'use-query-params'
import { CustomReactRouterAdapter } from '@local/Utils/Helpers/CustomReactRouterAdapter'
import { SnackbarProvider } from 'notistack'
import { useQueryParamsOptions } from '@local/Utils/Hooks/useQueryParamsOptions'
import { WebinarsList } from '@local/WebinarsList/WebinarsList'
import { GTMTracker } from '@trr/gtm-tracking'
import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'

export enum Pages {
  HittaAktiviteter = 'hitta-aktiviteter',
  Aktivitet = 'aktivitet',
  Inspelade = 'inspelade',
  KundInspelade = 'kund-inspelade',
  HittaKundAktiviteter = 'hitta-kund-aktiviteter',
  KundAktivitet = 'kund-aktivitet',
  HittaAktiviteterMedarbetare = 'aktiviteter-for-tjansteman',
  AktivitetMedarbetare = 'aktivitet-medarbetare',
  HittaKundAktiviteterMedarbetare = 'aktiviteter-for-arbetsgivare',
  KundAktivitetMedarbetare = 'kund-aktivitet-medarbetare',
  BokadeAktiviteter = 'mina-bokade-aktiviteter',
  GenomfordaAktiviteter = 'mina-genomforda-aktiviteter',
}

const KundAktivitetPages = [
  Pages.KundAktivitet,
  Pages.KundInspelade,
  Pages.HittaKundAktiviteter,
  Pages.KundAktivitetMedarbetare,
  Pages.HittaKundAktiviteterMedarbetare,
]

const MedarbetareAktivitiesPages = [
  Pages.AktivitetMedarbetare,
  Pages.KundAktivitetMedarbetare,
  Pages.HittaAktiviteterMedarbetare,
  Pages.HittaKundAktiviteterMedarbetare,
]

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)

  return (
    <ThemeProvider theme={theme}>
      <GTMTracker mfName="aktivitetsbokningar">
        <Provider store={store}>
          <AppShellDataProvider value={appShellData}>
            <UserContextProvider
              value={{
                isCustomer: KundAktivitetPages.includes(
                  appShellData.currentKey as Pages
                ),
                isMedarbetare: MedarbetareAktivitiesPages.includes(
                  appShellData.currentKey as Pages
                ),
              }}
            >
              <SnackbarProvider>
                <QueryParamProvider
                  adapter={CustomReactRouterAdapter}
                  options={useQueryParamsOptions}
                >
                  <AppShellRouter currentKey={appShellData.currentKey}>
                    <KeyRoute
                      urlKey={[
                        Pages.HittaAktiviteter,
                        Pages.HittaKundAktiviteter,
                      ]}
                    >
                      <ActivitiesList />
                    </KeyRoute>
                    <KeyRoute urlKey={[Pages.Aktivitet, Pages.KundAktivitet]}>
                      <Activity />
                    </KeyRoute>
                    <KeyRoute
                      urlKey={[
                        Pages.HittaAktiviteterMedarbetare,
                        Pages.HittaKundAktiviteterMedarbetare,
                      ]}
                    >
                      <MedarbetareActivitiesList />
                    </KeyRoute>
                    <KeyRoute
                      urlKey={[
                        Pages.AktivitetMedarbetare,
                        Pages.KundAktivitetMedarbetare,
                      ]}
                    >
                      <MedarbetareActivity />
                    </KeyRoute>
                    <KeyRoute urlKey={Pages.BokadeAktiviteter}>
                      <RegisteredActivitiesList />
                    </KeyRoute>
                    <KeyRoute urlKey={Pages.GenomfordaAktiviteter}>
                      <AttendedActivitiesList />
                    </KeyRoute>
                    <KeyRoute urlKey={[Pages.Inspelade, Pages.KundInspelade]}>
                      <WebinarsList />
                    </KeyRoute>
                  </AppShellRouter>
                </QueryParamProvider>
              </SnackbarProvider>
            </UserContextProvider>
          </AppShellDataProvider>
        </Provider>
      </GTMTracker>
    </ThemeProvider>
  )
}

export default App
