import { activitiesListApi } from '@local/ActivitiesList/services'
import { medarbetareActivitiesListApi } from '@local/Medarbetare/Services/medarbetareActivitiesList.service'
import { activityApi } from '@local/Activity/services/activity.service'
import { mailSignupApi } from '@local/Activity/services/mailSignup.service'
import { signupApi } from '@local/Activity/services/singup.service'
import { medarbetareActivityApi } from '@local/Medarbetare/Services/medarbetareActivity.service'
import { userActivitiesApi } from '@local/UserActivities/services/userActivities.service'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { webinarsApi } from '@local/WebinarsList/services/webinar.service'

const rootReducer = combineReducers({
  [activitiesListApi.reducerPath]: activitiesListApi.reducer,
  [medarbetareActivitiesListApi.reducerPath]:
    medarbetareActivitiesListApi.reducer,
  [webinarsApi.reducerPath]: webinarsApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [medarbetareActivityApi.reducerPath]: medarbetareActivityApi.reducer,
  [mailSignupApi.reducerPath]: mailSignupApi.reducer,
  [signupApi.reducerPath]: signupApi.reducer,
  [userActivitiesApi.reducerPath]: userActivitiesApi.reducer,
})

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        activitiesListApi.middleware,
        medarbetareActivitiesListApi.middleware,
        webinarsApi.middleware,
        activityApi.middleware,
        medarbetareActivityApi.middleware,
        mailSignupApi.middleware,
        signupApi.middleware,
        userActivitiesApi.middleware,
      ]),
    preloadedState,
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store
