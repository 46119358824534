import React, { useState } from 'react'
import { IPart } from '@local/Activity/services/activity.types'
import { getStageLocation } from '@local/Utils/Helpers/getLocation'
import { getTimespan } from '@local/Utils/Helpers/getTimespan'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { date } from '@trr/frontend-datetime'
import { isBefore } from 'date-fns'
import { equals } from 'ramda'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'

interface CallToActionPartsProps {
  parts?: IPart[]
  canUnregister?: boolean
  hasStarted?: boolean
  isOnline?: boolean
}

const CallToActionParts: React.FunctionComponent<CallToActionPartsProps> = ({
  parts,
  isOnline,
}) => {
  const t = useEpiCustomDictionary(['activity'])
  const [expandedStages, setExpandedStages] = useState(true)

  if (!parts?.length) {
    return null
  }

  const toggleExpanded = () => {
    setExpandedStages(!expandedStages)
  }

  const expandStagesLabel = expandedStages
    ? `Dölj alla ${t('callToActionShowStagesText')}`
    : `Visa alla ${t('callToActionShowStagesText')}`

  return (
    <>
      {parts?.length > 1 && (
        <CardListItem>
          <Accordion
            onChange={toggleExpanded}
            expanded={expandedStages}
            elevation={0}
            sx={{ width: '100%' }}
            disableGutters
          >
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 0 }}>
              <Typography variant="subtitle1">{expandStagesLabel}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List dense disablePadding>
                {parts?.map((part, index) => {
                  const now = new Date()
                  const stageStart = Date.parse(part.end.toString())
                  const beforeStatus = isBefore(stageStart, now)
                  const isNow = equals(
                    parts?.find((p) =>
                      isBefore(new Date(), Date.parse(p.end.toString()))
                    ),
                    part
                  )
                  return (
                    <CardListItem
                      key={index}
                      divider={false}
                      sx={{ paddingLeft: 3 }}
                    >
                      <ListItemText
                        sx={{
                          fontWeight:
                            isNow && !beforeStatus ? 'bold' : 'normal',
                          textDecoration: beforeStatus
                            ? 'line-through'
                            : 'unset',
                        }}
                        primary={`${date.format(part.start)} | ${getTimespan(
                          part
                        )}`}
                        secondary={
                          isOnline ? (
                            getStageLocation(isOnline, part)
                          ) : (
                            <Link
                              href={`https://maps.google.com?q=${getStageLocation(
                                isOnline,
                                part
                              )}`}
                              target="_blank"
                            >
                              {getStageLocation(isOnline, part)}
                            </Link>
                          )
                        }
                      />
                    </CardListItem>
                  )
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </CardListItem>
      )}
    </>
  )
}

export default CallToActionParts
