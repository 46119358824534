import React from 'react'
import { ChipPopover } from '@local/ActivitiesList/Components/Filtering/ChipPopover/ChipPopover'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { ActivityformFilterList } from '@local/ActivitiesList/Components/Filtering/ActivityformFilter/ActivityformFilterList'

export const ChipActivityformFilter = () => {
  const {
    searchParams: { activityForm },
    unsetSearchParamField,
  } = useSearchQueryParams()

  const handleReset = () => {
    unsetSearchParamField('activityForm')
  }

  return (
    <ChipPopover
      label="Aktivitetsform"
      selectCount={activityForm?.length}
      chipTestId="listFilterActivityformChip"
      handleReset={handleReset}
    >
      <ActivityformFilterList />
    </ChipPopover>
  )
}
