import React, { useState } from 'react'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { getStageLocation } from '@local/Utils/Helpers/getLocation'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import useCtaStatuses from '@local/Utils/Hooks/useCtaStatuses'
import {
  LIFECYCLE_STATUS,
  REGISTRATION_AVAILABILITY,
} from '@local/Activity/ActivityStatuses'
import { FromSourceTypes } from '@local/Types'
import useToggle from '@local/Utils/Hooks/useToggle'
import { MailAnsokan } from '@local/Activity/Components/MailAnsokan/MailAnsokan'
import {
  IActivityResponse,
  TargetGroups,
} from '@local/Activity/services/activity.types'
import CallToActionParts from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionParts/CallToActionParts'
import CallToActionHasStarted from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionHasStarted/CallToActionHasStarted'
import CallToActionHasPassed from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionHasPassed/CallToActionHasPassed'
import CallToActionInformation from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionInformation/CallToActionInformation'
import CallToActionOccasions from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionOccasions/CallToActionOccasions'
import { Link as MuiLink, List, Typography } from '@mui/material'
import { CallToAction } from '@local/Activity/Components/CallToAction/CallToAction'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { Link } from 'react-router-dom'

interface IActivityCallToAction {
  activity: IActivityResponse
}

export const ActivityCallToAction: React.FunctionComponent<
  IActivityCallToAction
> = ({ activity }) => {
  const t = useEpiCustomDictionary(['activity'])
  const {
    searchParams: { id, fromSource },
  } = useSearchQueryParams()
  const { isCustomer } = useUserContext()
  const { handleToggle, toggle: showAnsokanModal } = useToggle()
  const [isMailRegistered, setIsMailRegistered] = useState<boolean>(false)

  const handleClose = (registered = false) => {
    handleToggle()
    setIsMailRegistered(registered)
  }

  const {
    registration: {
      registrationAvailability,
      vacancies,
      hasVacancies,
      hasReserveVacancies,
      lastRegistrationDate,
      capacity,
    },
    schedule: { parts, lifecycleStatus, start },
    schedule,
    isOnline,
    preparation,
    activity: { name, form },
    targetAudience: { targetGroup },
  } = activity

  const {
    ctaAreaTitleText,
    ctaAreaPrimaryButtonStates: [primaryButtonText, primaryButtonOnClick],
    ctaAreaSecondaryButtonStates: [secondaryButtonText, secondaryButtonOnClick],
    ctaAreaRoundCanUnRegister,
    ctaAreaRoundHasStarted,
    ctaAreaRoundHasPassed,
    ctaAreaRoundHasStartedOrPassed,
    ctaAreaRoundFullyBooked,
    ctaAreaRoundUserHasWrongRoles,
    ctaAreaRoundRequiresAdvisorApproval,
  } = useCtaStatuses({
    lifecycleStatus,
    registrationAvailability,
    isCustomerUrl: isCustomer,
    schedule,
    activityId: id,
    activityName: name,
    fromSource,
    hasVacancies,
    hasReserveVacancies,
    targetGroup,
  })

  const hasStarted =
    lifecycleStatus === LIFECYCLE_STATUS.ONGOING &&
    registrationAvailability !==
      REGISTRATION_AVAILABILITY.NO_REGISTRATION_REQUIRED

  if (parts?.length === 0) {
    return null
  }

  return (
    <>
      {showAnsokanModal && (
        <MailAnsokan
          activityName={name}
          activityStartPart={parts[0]}
          activityLocation={getStageLocation(isOnline, parts[0]) || 'Online'}
          handleClose={handleClose}
        />
      )}
      <CallToAction
        ctaAreaTitle={ctaAreaTitleText}
        primaryButtonText={primaryButtonText}
        primaryButtonOnClick={primaryButtonOnClick}
        secondaryButtonText={
          secondaryButtonText !== primaryButtonText ? secondaryButtonText : ''
        }
        secondaryButtonOnClick={secondaryButtonOnClick}
        data-testid="ctaArea"
      >
        {!ctaAreaRoundHasPassed && (
          <List>
            <CallToActionOccasions activity={activity} />

            <CallToActionParts
              parts={parts}
              canUnregister={ctaAreaRoundCanUnRegister}
              hasStarted={ctaAreaRoundHasStarted}
              isOnline={isOnline}
            />
            <CallToActionInformation
              timeInMinutes={preparation.timeRequiredInMinutes}
              lastRegisrationDate={lastRegistrationDate || start}
              activityForm={form}
              vacancies={vacancies}
              capacity={capacity}
            />
            {hasStarted && <CallToActionHasStarted />}
          </List>
        )}

        {ctaAreaRoundHasPassed && (
          <CallToActionHasPassed isOnline={isOnline} parts={parts} />
        )}

        {ctaAreaRoundFullyBooked && (
          <Typography variant="subtitle1" component="p">
            {t('occasionIsFullInfo')}
          </Typography>
        )}
        {ctaAreaRoundRequiresAdvisorApproval &&
          targetGroup !== TargetGroups.Parter && (
            <Typography variant="subtitle1" component="p">
              {t('occasionRequiresAdvisorApprovalText')}&nbsp;
              <MuiLink
                to={{ pathname: t('occasionRequiresAdvisorApprovalUrl') }}
                component={Link}
              >
                din rådgivare
              </MuiLink>
            </Typography>
          )}
        {targetGroup === TargetGroups.Parter && (
          <Typography variant="subtitle1" component="p">
            {!isMailRegistered ? (
              <Typography variant="subtitle1" component="p">
                {t('occasionIsForParts')} Anmäl dig&nbsp;
                <MuiLink href="#" onClick={handleToggle}>
                  här
                </MuiLink>
                .
              </Typography>
            ) : (
              <span>{t('formAnsokanSuccessMessage')}</span>
            )}
          </Typography>
        )}
        {ctaAreaRoundUserHasWrongRoles &&
          targetGroup !== TargetGroups.Parter && (
            <Typography variant="subtitle1" component="p">
              <span>
                {isCustomer
                  ? t('occasionWrongRoleCustomer')
                  : t('occasionWrongRoleClient')}
              </span>
            </Typography>
          )}
        {isOnline &&
          ((registrationAvailability ===
            REGISTRATION_AVAILABILITY.NO_REGISTRATION_REQUIRED &&
            fromSource === FromSourceTypes.Calendar) ||
            registrationAvailability ===
              REGISTRATION_AVAILABILITY.ALREADY_REGISTERED ||
            registrationAvailability ===
              REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE) &&
          !ctaAreaRoundHasStartedOrPassed &&
          lifecycleStatus !== LIFECYCLE_STATUS.COMMENCING && (
            <Typography variant="subtitle1" component="p">
              <span>{t('connectionLinkWillAppear')}</span>
            </Typography>
          )}
      </CallToAction>
    </>
  )
}
