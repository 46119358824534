import React from 'react'
import { ActivityForm } from '@local/Activity/services/activity.types'
import EpiParser from '@local/Activity/Components/Layout/EpiParser'
import { convertSwedishCharacters } from '@local/Activity/Components/ActivityDescription/Utils/convertSwedishCharacters'
import { useEpiContent } from '@trr/app-shell-data'
import { AktivitetsbokningarContent } from '@local/Types'

interface ActivityDescriptionProps {
  form: ActivityForm
}

export const ActivityDescription = ({ form }: ActivityDescriptionProps) => {
  const { activityBaseDescription } =
    useEpiContent<AktivitetsbokningarContent>()
  const formattedFormName = convertSwedishCharacters(form)

  if (activityBaseDescription?.[formattedFormName] === undefined) {
    return
  }

  return (
    <EpiParser body={activityBaseDescription[formattedFormName].mainBody} />
  )
}
