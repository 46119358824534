import { IPart } from '@local/Activity/services/activity.types'
import { getStageLocation } from '@local/Utils/Helpers/getLocation'
import { getTimespan } from '@local/Utils/Helpers/getTimespan'
import { date } from '@trr/frontend-datetime'
import React from 'react'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { List, ListItemText } from '@mui/material'

interface CallToActionHasPassedProps {
  isOnline: boolean
  parts?: IPart[]
}

const CallToActionHasPassed: React.FunctionComponent<
  CallToActionHasPassedProps
> = ({ isOnline, parts }) => {
  if (!parts?.length) {
    return null
  }

  return (
    <List>
      {parts.map((part) => (
        <CardListItem key={JSON.stringify(part)}>
          <ListItemText
            sx={{
              textDecoration: 'line-through',
            }}
            secondary={getStageLocation(isOnline, part)}
            primary={`${date.format(part.start)} | ${getTimespan(part)}`}
          />
        </CardListItem>
      ))}
    </List>
  )
}

export default CallToActionHasPassed
