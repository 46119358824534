import React from 'react'
import { date } from '@trr/frontend-datetime'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import Close from '@mui/icons-material/Close'
import Height from '@mui/icons-material/Height'
import InsertInvitation from '@mui/icons-material/InsertInvitation'
import YouTube from 'react-youtube'

interface IVideoModal {
  toggled: boolean
  handleClose?: () => void
  videoId?: string
  broadcastDate?: string
  body?: string
  heading?: string
}

export const VideoModal: React.FunctionComponent<IVideoModal> = ({
  toggled,
  handleClose,
  videoId,
  broadcastDate,
  body,
  heading,
}) =>
  toggled && (
    <Dialog
      data-testid="videoModal"
      onClose={handleClose}
      open
      maxWidth="md"
      fullWidth
    >
      <DialogTitle marginBottom={3}>
        <IconButton
          aria-label="Stäng dialog"
          data-testid="videoModal--close-button"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {videoId && toggled && (
          <YouTube
            videoId={videoId}
            opts={{
              width: '100%',
              height: '400px',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                rel: 0,
              },
            }}
          />
        )}
        <List dense>
          {broadcastDate && (
            <CardListItem divider={false}>
              <CardListItemIcon aria-hidden>
                <InsertInvitation />
              </CardListItemIcon>
              <ListItemText
                primary={date.format(broadcastDate, { style: 'numeric' })}
              />
            </CardListItem>
          )}
          <Typography
            variant="h2"
            component="h1"
            gutterBottom={!!body}
            tabIndex={0}
          >
            {heading}
          </Typography>
          {body && <Typography variant="body1">{body}</Typography>}
        </List>
      </DialogContent>
    </Dialog>
  )
