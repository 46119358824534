import React from 'react'
import { includes } from 'ramda'
import { REGISTRATION_AVAILABILITY } from '@local/Activity/ActivityStatuses'
import { useAuthentication, useEpiCustomDictionary } from '@trr/app-shell-data'
import { useGetActivityQuery } from '@local/Activity/services/activity.service'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { ISignParameters } from '@local/Activity/services/signup.types'
import { RegistrationTypes } from '@local/Activity/services/singup.service'
import { Button, CircularProgress } from '@mui/material'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'

const secondaryState = [
  REGISTRATION_AVAILABILITY.ALREADY_REGISTERED,
  REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE,
]
const disabledState = [
  REGISTRATION_AVAILABILITY.FULLY_BOOKED,
  REGISTRATION_AVAILABILITY.PENDING_OPERATION,
  REGISTRATION_AVAILABILITY.REQUIRES_ADVISOR_APPROVAL,
]

interface IRegistrationButton {
  customClass?: string
  tertiary?: boolean
  isReadyForSignups: boolean
  triggerSign: (args: ISignParameters) => unknown
}

const RegistrationButton: React.FunctionComponent<IRegistrationButton> = ({
  isReadyForSignups,
  triggerSign,
}) => {
  const {
    searchParams: { id },
  } = useSearchQueryParams()
  const { isCustomer } = useUserContext()
  const { sub } = useAuthentication()

  const {
    data: {
      registration: { registrationAvailability },
    },
  } = useGetActivityQuery({ id, isCustomer }, { skip: id === '' })

  const showAdvisorApproval =
    registrationAvailability ===
    REGISTRATION_AVAILABILITY.REQUIRES_ADVISOR_APPROVAL
  const t = useEpiCustomDictionary(['activity'])

  const handleRegistrationButtonClick = () => {
    if (
      registrationAvailability ===
        REGISTRATION_AVAILABILITY.ALREADY_REGISTERED ||
      registrationAvailability ===
        REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE
    ) {
      triggerSign({ id, type: RegistrationTypes.unregister, userId: sub })
    } else {
      triggerSign({ id, type: RegistrationTypes.register, userId: sub })
    }
  }

  if (
    showAdvisorApproval ||
    includes(registrationAvailability, disabledState)
  ) {
    return null
  }

  return isReadyForSignups ? (
    <Button
      variant={
        includes(registrationAvailability, secondaryState)
          ? 'outlined'
          : 'contained'
      }
      data-testid="actionStepRegistrationButton"
      size="large"
      onClick={handleRegistrationButtonClick}
    >
      {t(registrationAvailability).length > 0
        ? t(registrationAvailability)
        : ''}
    </Button>
  ) : (
    <CircularProgress />
  )
}

export default RegistrationButton
