import React from 'react'
import { ChipPopover } from '@local/ActivitiesList/Components/Filtering/ChipPopover/ChipPopover'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { AreasOfInterestFilterList } from '@local/ActivitiesList/Components/Filtering/AreasOfInterestFilter/AreasOfInterestFilterList'

export const ChipAreasOfInterestFilter = () => {
  const {
    searchParams: { areasOfInterest },
    unsetSearchParamField,
  } = useSearchQueryParams()

  const handleReset = () => {
    unsetSearchParamField('areasOfInterest')
  }

  return (
    <ChipPopover
      label="Kategorier"
      selectCount={areasOfInterest?.length}
      chipTestId="listFilterAreasOfInterestChip"
      handleReset={handleReset}
    >
      <AreasOfInterestFilterList />
    </ChipPopover>
  )
}
