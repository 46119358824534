import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { REGISTRATION_AVAILABILITY } from '@local/Activity/ActivityStatuses'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { useGetActivityQuery } from '@local/Activity/services/activity.service'
import RegistrationButton from '@local/Activity/Components/RegistrationButton'
import { ISignParameters } from '@local/Activity/services/signup.types'
import { date } from '@trr/frontend-datetime'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { getPreparationTime } from '@local/Utils/Helpers/getPreparationTime'
import { getTimespan } from '@local/Utils/Helpers/getTimespan'
import {
  getAddress,
  getRadioButtonAdress,
} from '@local/Utils/Helpers/activity.helpers'
import {
  Alert,
  AlertTitle,
  Box,
  Link,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import Task from '@mui/icons-material/Task'
import {
  signupSteps,
  useSearchQueryParams,
} from '@local/Utils/Hooks/useSearchQueryParams'

interface IActionStep {
  triggerSign: (arg: ISignParameters) => unknown
  isReadyForSignups: boolean
}

export const ActionStep: React.FunctionComponent<IActionStep> = ({
  triggerSign,
  isReadyForSignups,
}) => {
  const {
    searchParams: { id },
    setSearchParamField,
  } = useSearchQueryParams()
  const { isCustomer } = useUserContext()

  const {
    data: {
      preparation,
      activity: { name },
      registration: { registrationAvailability },
      schedule: { parts },

      targetAudience: { location },
    },
    isLoading,
    error,
  } = useGetActivityQuery({ id, isCustomer }, { skip: id === '' })

  const history = useHistory()
  const t = useEpiCustomDictionary(['activity'])
  useEffect(() => {
    history.action === 'POP' && setSearchParamField(signupSteps.Start, 'step')
  })

  const formattedTime: { hours: number; minutes: number } = !isLoading &&
    preparation.timeRequiredInMinutes && {
      hours: Math.floor(preparation.timeRequiredInMinutes / 60),
      minutes: preparation.timeRequiredInMinutes % 60,
    }

  if (isLoading) {
    return null
  }

  return (
    <div data-testid="actionStep">
      {error && (
        <Alert severity="error">
          <AlertTitle>{t('registrationModalSystemFeedbackText')}</AlertTitle>
        </Alert>
      )}

      {registrationAvailability !==
        REGISTRATION_AVAILABILITY.ALREADY_REGISTERED &&
        registrationAvailability !==
          REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE && (
          <Typography variant="h2" component="h1" gutterBottom>
            {name}
          </Typography>
        )}

      {registrationAvailability ===
        REGISTRATION_AVAILABILITY.ALREADY_REGISTERED ||
      registrationAvailability ===
        REGISTRATION_AVAILABILITY.ALREADY_REGISTERED_RESERVE ? (
        <>
          <Typography variant="h2" component="h1" gutterBottom>
            {t('unregisterModalHeader')}
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            {`${name} - ${location[0]}`}
          </Typography>
          <List dense>
            {parts?.map((part) => (
              <CardListItem key={JSON.stringify(part)} divider={false}>
                <ListItemText
                  secondary={
                    getRadioButtonAdress(part.physicalAddress) === 'Online' ? (
                      getRadioButtonAdress(part.physicalAddress)
                    ) : (
                      <Link
                        href={`https://maps.google.com/?q=${getAddress(
                          part.physicalAddress
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      />
                    )
                  }
                  primary={`${date.format(part.start)} | ${getTimespan(part)}`}
                />
              </CardListItem>
            ))}
          </List>
          <div>
            <RegistrationButton
              isReadyForSignups={isReadyForSignups}
              triggerSign={triggerSign}
            />
          </div>
        </>
      ) : (
        <div>
          {id && (
            <>
              <Typography variant="h4" component="h2" gutterBottom>
                {t('registrationOccasionInformation')}
              </Typography>
              {parts.length > 1 && (
                <Typography variant="h5" gutterBottom>
                  {'Delmoment:'}
                </Typography>
              )}
              <List dense sx={{ mb: 5 }}>
                {parts?.map((part) => (
                  <CardListItem key={JSON.stringify(part)} divider={false}>
                    <ListItemText
                      secondary={
                        getRadioButtonAdress(part.physicalAddress) ===
                        'Online' ? (
                          getRadioButtonAdress(part.physicalAddress)
                        ) : (
                          <Link
                            href={`https://maps.google.com/?q=${getAddress(
                              part.physicalAddress
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          />
                        )
                      }
                      primary={`${date.format(part.start)} | ${getTimespan(
                        part
                      )}`}
                    />
                  </CardListItem>
                ))}
              </List>
              {preparation?.description && (
                <Box sx={{ mb: 5 }}>
                  <Typography variant="h4" component="h3" gutterBottom>
                    {t('preparationsTextHeader')}
                  </Typography>
                  {(formattedTime?.hours || formattedTime?.minutes) && (
                    <List>
                      <CardListItem divider={false}>
                        <CardListItemIcon aria-hidden>
                          <Task />
                        </CardListItemIcon>
                        <ListItemText
                          primary={`${getPreparationTime(formattedTime)}`}
                          secondary={t('preparationTimeText')}
                        />
                      </CardListItem>
                    </List>
                  )}
                  <Typography variant="body1">
                    {t('preparationsText')}
                  </Typography>
                </Box>
              )}
              <div>
                {registrationAvailability ===
                  REGISTRATION_AVAILABILITY.REQUIRES_ADVISOR_APPROVAL && (
                  <Box padding={4}>
                    <Typography variant="h4">
                      {t('requiresAdvisorApproval')}
                    </Typography>
                    <Typography variant="body2">
                      {t('requiresAdvisorApprovalInfo')}
                    </Typography>
                  </Box>
                )}
                {registrationAvailability ===
                  REGISTRATION_AVAILABILITY.FULLY_BOOKED && (
                  <Box padding={4}>
                    <Typography variant="h4">
                      {t('REGISTRATION_AVAILABILITY.FULLY_BOOKED')}
                    </Typography>
                    <Typography variant="body2">
                      {t('canNotRegisterInfo')}
                    </Typography>
                  </Box>
                )}
                <RegistrationButton
                  isReadyForSignups={isReadyForSignups}
                  triggerSign={triggerSign}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
