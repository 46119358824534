import React from 'react'
import { ActivityForm } from '@local/Activity/services/activity.types'
import { preparationTimeFormatted } from '@local/Utils/Helpers/activity.helpers'
import { getPreparationTime } from '@local/Utils/Helpers/getPreparationTime'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { date } from '@trr/frontend-datetime'
import { ListItemText } from '@mui/material'
import GroupsOutlined from '@mui/icons-material/GroupsOutlined'
import InsertInvitation from '@mui/icons-material/InsertInvitation'
import WatchLater from '@mui/icons-material/WatchLater'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { ActivityFormIconCardListItem } from '@local/Components/ActivityFormIconCardListItem'
import { getVacancyText } from '@local/Utils/Helpers/vacancyHelper'

interface CallToActionInformationProps {
  timeInMinutes: number
  lastRegisrationDate: string
  activityForm: ActivityForm
  vacancies: number
  capacity: number
}

const CallToActionInformation: React.FunctionComponent<
  CallToActionInformationProps
> = ({
  timeInMinutes,
  lastRegisrationDate,
  activityForm,
  vacancies,
  capacity,
}) => {
  const t = useEpiCustomDictionary(['activity'])

  const formattedTime: { hours: number; minutes: number } =
    timeInMinutes && preparationTimeFormatted(timeInMinutes)

  const formattedVacancies = getVacancyText(vacancies, capacity, t)
  const isNotWebbinarium = activityForm !== 'Webbinarium'
  return (
    <>
      {activityForm && <ActivityFormIconCardListItem form={activityForm} />}
      {Boolean(formattedTime) && (
        <CardListItem divider={false}>
          <CardListItemIcon aria-hidden>
            <WatchLater />
          </CardListItemIcon>
          <ListItemText
            primary={getPreparationTime(formattedTime)}
            secondary={t('förberedelsetid')}
          />
        </CardListItem>
      )}
      <CardListItem divider={false}>
        <CardListItemIcon aria-hidden>
          <InsertInvitation />
        </CardListItemIcon>
        <ListItemText
          primary={date.format(lastRegisrationDate)}
          secondary={t('callToActionLastRegistrationDate')}
        />
      </CardListItem>
      {isNotWebbinarium && (
        <CardListItem divider={false}>
          <CardListItemIcon aria-hidden>
            <GroupsOutlined />
          </CardListItemIcon>
          <ListItemText primary={formattedVacancies} />
        </CardListItem>
      )}
    </>
  )
}

export default CallToActionInformation
