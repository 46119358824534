import React from 'react'
import { FilterListItem } from '@local/ActivitiesList/Components/Filtering/Components/FilterListItem'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { useGetFilteredMedarbetareActivitiesQuery } from '@local/Medarbetare/Services/medarbetareActivitiesList.service'
import { useGetFilteredActivitiesQuery } from '@local/ActivitiesList/services'
import { getActivityformFacetsFromActivity } from '@local/ActivitiesList/Components/Filtering/Utils/filterHelpers'
import { capitalize } from '@local/Utils/Helpers/stringHelper'
import { List, Typography } from '@mui/material'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

export const ActivityformFilterList = () => {
  const {
    searchParams: {
      activityForm: pickedActivityForms,
      cities,
      isOnline,
      areasOfInterest,
      targetGroups,
    },
    setSearchParamField,
  } = useSearchQueryParams()
  const { isMedarbetare, isCustomer } = useUserContext()
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()
  const searchParams = {
    areasOfInterest,
    targetGroups,
    cities,
    isOnline: isOnline ? isOnline : undefined,
  }
  const { data: medarbetareActivities } =
    useGetFilteredMedarbetareActivitiesQuery(
      { searchParams, isCustomer: isCustomer },
      { skip: !isMedarbetare }
    )
  const { data: publicActivities } = useGetFilteredActivitiesQuery(
    { searchParams, isCustomer: isCustomer },
    { skip: isMedarbetare }
  )

  const activities = isMedarbetare ? medarbetareActivities : publicActivities
  const activityForms = getActivityformFacetsFromActivity(activities)

  const handleSetActivityForm = (activityForm?: string) => {
    if (shouldTrack && !pickedActivityForms?.includes(activityForm)) {
      trackClick('Filtrering', { label: `Aktivitetsform: ${activityForm}` })
    }
    setSearchParamField(activityForm, 'activityForm')
  }

  if (!activityForms || activityForms.length === 0) {
    return <Typography ml={1.5}>Inga aktivitetsformer kunde hittas</Typography>
  }

  return (
    <List data-testid="listFilterActivityformList" disablePadding>
      {activityForms.map((form) => (
        <FilterListItem
          key={form.name}
          title={capitalize(form.name)}
          amount={form.count}
          handleOnClick={() => handleSetActivityForm(form.name)}
          active={pickedActivityForms.includes(form.name)}
          disabled={
            !pickedActivityForms.includes(form.name) && form.count === 0
          }
        />
      ))}
    </List>
  )
}
