import React from 'react'
import { FilterAccordion } from '@local/ActivitiesList/Components/Filtering/Components/FilterAccordion'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { RecordedAreasOfInterestFilterList } from '@local/ActivitiesList/Components/Filtering/RecordedAreasOfInterestFilter/RecordedAreasOfInterestFilterList'

export const RecordedExpandingAreasOfInterestFilter = () => {
  const {
    searchParams: { areasOfInterest },
  } = useSearchQueryParams()

  return (
    <FilterAccordion title="Kategorier" selectCount={areasOfInterest?.length}>
      <RecordedAreasOfInterestFilterList />
    </FilterAccordion>
  )
}
