import { View } from '@local/ActivitiesList/services/activitiesList.types'
import getConfig from '@local/Utils/ConfigService/getConfig'
import { getCookieValue } from '@local/Utils/Helpers/cookieValues'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'

export const { API_URL, PUBLIC_WEB_API_URL } = getConfig()

export const viewTypeParam = (isCustomer: boolean) =>
  `view=${isCustomer ? View.Kund : View.Anstalld}`

const trrUseOptimizelyNext = () =>
  getCookieValue('trr-use-optimizely-next') === 'true'

export const trrFetchBaseQuery = (isMedarbetare = false) =>
  fetchBaseQuery({
    baseUrl: isMedarbetare ? `${PUBLIC_WEB_API_URL}` : `${API_URL}`,
    prepareHeaders: (headers) => {
      getIdToken() && headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Accept-Language', 'sv')
      if (trrUseOptimizelyNext()) headers.set('trr-use-optimizely-next', 'true')
      return headers
    },
  })
