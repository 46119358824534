import { CardContent, styled } from '@mui/material'

export const UserActivityCardChipContent = styled(CardContent)(({ theme }) => ({
  mt: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}))

export const UserActivityCardPrimaryContent = styled(CardContent)(
  ({ theme }) => ({
    pt: theme.spacing(0),
    pb: theme.spacing(0),
  })
)
