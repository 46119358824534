import { sortAreasOfInterest } from '@local/ActivitiesList/services/activitiesList.service.helpers'
import {
  IGetActivityLocationsResponse,
  IGetAreasOfInterestResponse,
  IGetFilteredActivitiesRequest,
  View,
} from '@local/ActivitiesList/services/activitiesList.types'
import { IGetFilteredActivitiesMedarbetareResponse } from '@local/Medarbetare/Services/medarbetareActivitiesList.types'
import { ISelectOption } from '@local/Types'
import { generateImage } from '@local/Utils/Helpers/ImageBank/imageHelpers'
import { createActivityRequestParams } from '@local/Utils/Helpers/apiHelpers'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'
import queryString from 'query-string'

export const medarbetareActivitiesListApi = createApi({
  reducerPath: 'medarbetareActivitiesListApi',
  baseQuery: trrFetchBaseQuery(true),
  endpoints: (builder) => ({
    getFilteredMedarbetareActivities: builder.query<
      IGetFilteredActivitiesMedarbetareResponse,
      {
        searchParams: IGetFilteredActivitiesRequest
        isCustomer: boolean
      }
    >({
      query: (input) => {
        const request = createActivityRequestParams(
          input.searchParams,
          input.isCustomer
        )
        const params = queryString.stringify(request, {
          arrayFormat: 'index',
        })

        return {
          url: 'aktivitetsbokningar/admin/activities?' + params,
        }
      },
      transformResponse: (
        response: IGetFilteredActivitiesMedarbetareResponse,
        _,
        args
      ) => {
        const result = response.items.map((i) => {
          if (i?.imageUrl) return i
          const randomNumber = i.nextActivityRoundId.replace(/\D/g, '')
          const slot = Number(randomNumber[randomNumber.length - 1])
          return {
            ...i,
            imageUrl: generateImage(
              slot,
              'small',
              i.areasOfInterest[0],
              args.isCustomer
            ),
          }
        })
        return { ...response, items: result }
      },
    }),
    getAreasOfInterestMedarbetare: builder.query<ISelectOption[], void>({
      query: () => `aktivitetsbokningar/admin/areasofinterest`,
      transformResponse: (response: IGetAreasOfInterestResponse) => [
        ...response
          .map((interest) => ({
            value: interest.name,
            label: interest.name,
          }))
          .sort(sortAreasOfInterest),
      ],
    }),
    getActivityLocationsMedarbetare: builder.query<
      ISelectOption[],
      { isCustomer: boolean }
    >({
      query: (input) => ({
        url: `aktivitetsbokningar/admin/locations`,
        params: { view: input.isCustomer ? View.Kund : View.Anstalld },
      }),
      transformResponse: (response: IGetActivityLocationsResponse) => [
        ...response
          .filter((loc) => loc.name?.toLowerCase() !== 'hela sverige')
          .map((loc) => ({
            value: loc.name,
            label: loc.name,
          })),
      ],
    }),
  }),
})

export const {
  useGetFilteredMedarbetareActivitiesQuery,
  useGetActivityLocationsMedarbetareQuery,
  useGetAreasOfInterestMedarbetareQuery,
} = medarbetareActivitiesListApi
