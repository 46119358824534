import React, { useEffect } from 'react'
import { isEmpty } from 'ramda'
import { useHistory } from 'react-router-dom'
import { useAuthentication, useEpiCustomDictionary } from '@trr/app-shell-data'
import { useGetAttendedActivitiesQuery } from '@local/UserActivities/services/userActivities.service'
import AttendedActivityCard from '@local/UserActivities/AttendedActivitiesList/AttendedActivityCard'
import { stringByRole } from '@local/Utils/Helpers/stringByRole'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
  styled,
} from '@mui/material'
import { ActivityGrid } from '@local/Components/ActivityGrid'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'

const EmptyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  button: {
    alignSelf: 'center',
    marginTop: '3rem',
  },
})

const AttendedActivitiesList = (): JSX.Element => {
  const history = useHistory()
  const t = useEpiCustomDictionary(['registered'])
  const { isCustomer } = useUserContext()
  const { userRoles, sub } = useAuthentication()

  const gotoActivities = React.useCallback(() => {
    const path = isCustomer
      ? t('findActivities.customer')
      : t('findActivities.klient')
    history.push(path)
  }, [history, isCustomer, t])

  const {
    isLoading,
    data: attendedActivities,
    error,
    refetch,
  } = useGetAttendedActivitiesQuery(sub)

  useEffect(() => {
    void refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    (isEmpty(attendedActivities) && !isLoading) ||
    (!attendedActivities?.length && !isLoading) ||
    error
  ) {
    return (
      <EmptyWrapper>
        <EmptyResult
          description={
            userRoles?.includes('AvslutadKlient') ? '' : t('emptyListBody')
          }
          heading={t('emptyListTitle')}
        />
        {!userRoles?.includes('AvslutadKlient') && (
          <Button onClick={gotoActivities}>{t('findNewActivityText')}</Button>
        )}
      </EmptyWrapper>
    )
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <div>
      <Typography variant="body1" mb={3}>
        {t('findActivitiesLinkText')}
        <Link
          title="Länk till hitta aktiviteter"
          href={stringByRole({
            clientString: t('findActivities.klient'),
            customerString: t('findActivities.customer'),
            allRoles: userRoles,
          })}
        >
          {t('findActivitiesLinkLabel')}
        </Link>
      </Typography>
      <ActivityGrid data-testid="genomfort-tillfalle">
        {attendedActivities
          .map((attendedActivity) => (
            <Grid item xs={1} sm={1} md={1} key={attendedActivity.id}>
              <AttendedActivityCard {...attendedActivity} />
            </Grid>
          ))
          .reverse()}
      </ActivityGrid>
    </div>
  )
}

export default AttendedActivitiesList
