import React from 'react'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CallToActionNoOccasions } from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionNoOccasions/CallToActionNoOccasions'
import OccasionRadioButtonGroup from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionOccasions/OccasionRadioButtonGroup'
import { IActivityResponse } from '@local/Activity/services/activity.types'
import { IMedarbetareActivityResponse } from '@local/Medarbetare/Services/medarbetareActivity.types'

interface CallToActionOccasionsProps {
  activity: IActivityResponse | IMedarbetareActivityResponse
}

const CallToActionOccasions: React.FunctionComponent<
  CallToActionOccasionsProps
> = ({ activity }) => {
  if (activity.relatedActivityRounds?.length === 0) {
    return (
      <CallToActionNoOccasions
        schedule={activity.schedule}
        isOnline={activity.isOnline}
      />
    )
  }

  return (
    <CardListItem>
      <OccasionRadioButtonGroup activity={activity} />
    </CardListItem>
  )
}

export default CallToActionOccasions
