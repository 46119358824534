import { Avatar, styled } from '@mui/material'

export const FilterCountIndicator = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginLeft: theme.spacing(1.5),
  backgroundColor: theme.palette.secondary.main,
  color: '#fff !important',
  fontSize: theme.typography.body2.fontSize,
}))
