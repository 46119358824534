import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

interface ReadMoreDialogProps {
  title: string
  content: string | JSX.Element
  open: boolean
  handleClose: () => void
}

export const ReadMoreDialog = ({
  title,
  content,
  open,
  handleClose,
}: ReadMoreDialogProps) => (
  <Dialog
    open={open}
    onClose={handleClose}
    maxWidth="sm"
    fullWidth
    data-testid="administrativeInformationDialog"
  >
    <DialogTitle id="readmore-alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="readmore-alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="text" onClick={handleClose}>
        Stäng
      </Button>
    </DialogActions>
  </Dialog>
)
