// Optional/obligatory params are subject to change. Current guidelines as of 2024-03-15
interface UtmParams {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content?: string
  utm_term?: string
}

export const generateUtmParams = (params: UtmParams) =>
  Object.keys(params)
    .filter((key: keyof UtmParams) => params[key] && params[key] !== '')
    .map((key: keyof UtmParams) => `${key}=${params[key]}`)
    .join('&')

export const generateUtmDate = (date: Date) => {
  const monthName = date.toLocaleString('sv-SE', { month: 'long' })
  const abbreviatedYear = date.toLocaleString('sv-SE', { year: '2-digit' })
  return `${monthName}_${abbreviatedYear}`
}
