import { IActivityResponse } from '@local/Activity/services/activity.types'
import { generateImage } from '@local/Utils/Helpers/ImageBank/imageHelpers'
import { trrFetchBaseQuery, viewTypeParam } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'

export const activityApi = createApi({
  reducerPath: 'activityApi',
  baseQuery: trrFetchBaseQuery(),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getActivity: builder.query<
      IActivityResponse,
      {
        id: string
        isCustomer: boolean
      }
    >({
      query: (input) =>
        `/aktivitetsbokningar/ActivityRounds/${input.id}?${viewTypeParam(
          input.isCustomer
        )}`,

      transformResponse: (response: IActivityResponse, _, args) => {
        if (response.activity.imageUrl) return response
        const randomNumber = response.id.replace(/\D/g, '')
        const slot = Number(randomNumber[randomNumber.length - 1])
        return {
          ...response,
          activity: {
            ...response.activity,
            imageUrl:
              response.activity.imageUrl ??
              generateImage(
                slot,
                'big',
                response.activity.areasOfInterest[0],
                args.isCustomer
              ),
          },
        }
      },
    }),
  }),
})

export const { useGetActivityQuery } = activityApi
