import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import StartStep from '@local/Activity/Components/RegistrationSteps/StartStep'
import { TabName } from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { Pages } from '@local/App'
import { useGetMedarbetareActivityQuery } from '@local/Medarbetare/Services/medarbetareActivity.service'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { MedarbetareActivityCallToAction } from '@local/Medarbetare/MedarbetareActivityCallToAction/MedarbetareActivityCallToAction'
import { CircularProgress, Link } from '@mui/material'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'

export const MedarbetareActivity = () => {
  const t = useEpiCustomDictionary(['activity'])
  const history = useHistory()
  const { hash, pathname, search } = useLocation()
  const stickyRef = useRef(null)
  const {
    searchParams: { id },
  } = useSearchQueryParams()
  const { isCustomer } = useUserContext()
  const {
    data: activity,
    isLoading,
    error,
  } = useGetMedarbetareActivityQuery({ id, isCustomer }, { skip: id === '' })

  const [currentTab, setCurrentTab] = useState<TabName>(TabName.Start)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    switch (hash) {
      case `#${TabName.Start}`:
        break
      case `#${TabName.Preparations}`:
        setCurrentTab(TabName.Preparations)
        break
      case `#${TabName.Materials}`:
        setCurrentTab(TabName.Materials)
        break
      default:
        handleSetTab(TabName.Start)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetTab = (tab: TabName) => {
    setCurrentTab(tab)
    history.replace(`${pathname}${search}#${tab}`)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (error || !activity) {
    return (
      <>
        <EmptyResult
          heading={t('errorPage.title')}
          description={t('errorPage.description')}
          action={{
            label: t('errorPage.button'),
            onClick: () =>
              history.push(
                pathname.substring(
                  0,
                  pathname.lastIndexOf(`/${Pages.Aktivitet}`)
                )
              ),
          }}
        />
        <Link href={t('errorPage.linkUrl')}>{t('errorPage.linkText')}</Link>
      </>
    )
  }

  return (
    <>
      <StartStep
        currentTab={currentTab}
        stickyRef={stickyRef}
        onSetTab={handleSetTab}
        activity={activity}
      >
        <MedarbetareActivityCallToAction activity={activity} />
      </StartStep>
    </>
  )
}
