import React from 'react'

interface UserContext {
  isCustomer: boolean
  isMedarbetare?: boolean
}

export const Context = React.createContext<UserContext>({
  isCustomer: false,
  isMedarbetare: false,
})
export const UserContextProvider = Context.Provider
