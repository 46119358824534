import { TabName } from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import { IAdress, IPart } from '@local/Activity/services/activity.types'
import { getTimespan } from '@local/Utils/Helpers/getTimespan'
import { date } from '@trr/frontend-datetime'

export const tabNameFromHash = (hash: string): TabName => {
  switch (hash) {
    case `#${TabName.Start}`:
      return TabName.Start
    case `#${TabName.Preparations}`:
      return TabName.Preparations
    case `#${TabName.Materials}`:
      return TabName.Materials
    default:
      return TabName.Start
  }
}

export const preparationTimeFormatted = (
  timeInMinutes: number
): { hours: number; minutes: number } => ({
  hours: Math.floor(timeInMinutes / 60),
  minutes: timeInMinutes % 60,
})

export const getRadioButtonAdress = (adress: IAdress): string => {
  if (adress?.street && adress?.city) {
    return `${adress.street}${`\n${adress.city}`}`
  } else if (adress?.street) {
    return `${adress.street}`
  } else if (adress?.city) {
    return `${adress.city}`
  }

  return ''
}

export const getAddress = (adress: IAdress): string => {
  if (adress?.street && adress?.city) {
    return `${adress.street}${`, ${adress.city}`}`
  } else if (adress?.street) {
    return `${adress.street}`
  } else if (adress?.city) {
    return `${adress.city}`
  }

  return ''
}

export const getRadioButtonDate = (
  part: Pick<IPart, 'start' | 'end'>
): string => `${date.format(part.start)} | ${getTimespan(part as IPart)}`
