import React, { PropsWithChildren } from 'react'

interface AppShellRouterProps extends PropsWithChildren {
  currentKey: string
}

export const AppShellRouter: React.FunctionComponent<AppShellRouterProps> = ({
  currentKey,
  children,
}) => {
  let component = null

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (
        child.props.urlKey === currentKey ||
        (child.props.urlKey instanceof Array &&
          child.props.urlKey.includes(currentKey))
      ) {
        component = child
      }
    } else {
      component = null
    }
  })

  return component
}
