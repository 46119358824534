import { ISelectOption } from '@local/Types'
import { ActivityCategories } from '@local/Utils/Helpers/ImageBank/imageBank'

const categories = Object.values(ActivityCategories) as string[]

export const sortAreasOfInterest = (
  a: ISelectOption,
  b: ISelectOption
): number => {
  const aIndex = categories.indexOf(a.label)
  const bIndex = categories.indexOf(b.label)

  return aIndex > bIndex ? 1 : -1
}
