export const stringByRole = ({
  clientString,
  customerString,
  allRoles,
}: {
  clientString: string
  customerString: string
  allRoles: string[]
}): string => {
  const clientRoles = ['PausadKlient', 'AvslutadKlient', 'Klient']
  const customerRoles = ['KundOmfattas', 'KundOmfattasEj']
  const matchRole = (roleFilter: string[]) =>
    allRoles.some((item) => roleFilter.includes(item))

  const isClient = matchRole(clientRoles)
  const isCustomer = matchRole(customerRoles)
  if (isClient) {
    return clientString
  }
  if (isCustomer) {
    return customerString
  }
}
