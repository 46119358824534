import { Tags } from '@local/Medarbetare/Services/medarbetareActivity.types'
import {
  IGetAttendedActivitiesResponse,
  IGetRegisteredActivitiesResponse,
} from '@local/UserActivities/services/userActivities.types'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react'

export const userActivitiesApi = createApi({
  reducerPath: 'UserActivitiesApi',
  baseQuery: trrFetchBaseQuery(),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getRegisteredActivities: builder.query<
      IGetRegisteredActivitiesResponse,
      string
    >({
      query: (userId: string) =>
        `/aktivitetsbokningar/ActivityRounds/user/${userId}/current`,
      transformResponse: (
        baseQueryReturnValue: IGetRegisteredActivitiesResponse
      ) =>
        baseQueryReturnValue.map((activity) => ({
          ...activity,
          tags: activity.tags.filter((tag) => tag === Tags.CANCELLED),
        })),
    }),
    getAttendedActivities: builder.query<
      IGetAttendedActivitiesResponse,
      string
    >({
      query: (userId: string) =>
        `/aktivitetsbokningar/ActivityRounds/user/${userId}/attended`,
      transformResponse: (
        baseQueryReturnValue: IGetAttendedActivitiesResponse
      ) =>
        baseQueryReturnValue.map((activity) => ({
          ...activity,
          tags: activity.tags.filter((tag) => tag === Tags.CANCELLED),
        })),
    }),
  }),
})

export const {
  useGetAttendedActivitiesQuery,
  useGetRegisteredActivitiesQuery,
} = userActivitiesApi
