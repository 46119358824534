import { IMedarbetareActivityResponse } from '@local/Medarbetare/Services/medarbetareActivity.types'
import { generateImage } from '@local/Utils/Helpers/ImageBank/imageHelpers'
import { trrFetchBaseQuery, viewTypeParam } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react'

export const medarbetareActivityApi = createApi({
  reducerPath: 'medarbetareActivityApi',
  baseQuery: trrFetchBaseQuery(true),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getMedarbetareActivity: builder.query<
      IMedarbetareActivityResponse,
      {
        id: string
        isCustomer: boolean
      }
    >({
      query: (input) =>
        `/aktivitetsbokningar/admin/ActivityRounds/${input.id}?${viewTypeParam(
          input.isCustomer
        )}`,

      transformResponse: (response: IMedarbetareActivityResponse, _, args) => {
        if (response.activity.imageUrl) return response
        const randomNumber = response.id.replace(/\D/g, '')
        const slot = Number(randomNumber[randomNumber.length - 1])
        return {
          ...response,
          activity: {
            ...response.activity,
            imageUrl:
              response.activity.imageUrl ??
              generateImage(
                slot,
                'big',
                response.activity.areasOfInterest[0],
                args.isCustomer
              ),
          },
        }
      },
    }),
  }),
})

export const { useGetMedarbetareActivityQuery } = medarbetareActivityApi
