type MapToOutput<I, U = I> = (v: I) => U

export const lockScrollbar: MapToOutput<boolean, void> = (
  showModal: boolean
) => {
  const bg = document.getElementsByTagName('html')[0]
  if (showModal) {
    bg.style.overflowY = 'hidden'
  } else {
    bg.style.overflowY = 'scroll'
  }
}
