import { IAdress, IPart } from '@local/Activity/services/activity.types'
import { capitalize } from '@local/Utils/Helpers/stringHelper'

export const getLocation = (office: string, address: IAdress): string => {
  const city = address?.city
  const street = address?.street
  const adress = city ? city : street

  return adress ? capitalize(adress) : office
}

export const getStageLocation = (isWebinar: boolean, stage: IPart): string => {
  if (isWebinar) {
    return 'Online'
  }

  const adressInfo = []
  stage.physicalAddress?.street && adressInfo.push(stage.physicalAddress.street)
  stage.physicalAddress?.city && adressInfo.push(stage.physicalAddress.city)
  return adressInfo.join(', ') ?? ''
}
