export * from './content.types'

export enum FromSourceTypes {
  Calendar = 'calendar',
  Attended = 'attended',
  Registered = 'registered',
  Medarbetare = 'medarbetare',
}

export interface ISelectOption {
  label: string
  value: string | number
  id?: string
}
