import React from 'react'
import { ChipPopover } from '@local/ActivitiesList/Components/Filtering/ChipPopover/ChipPopover'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { LocationFilterList } from '@local/ActivitiesList/Components/Filtering/LocationFilter/LocationFilterList'

export const ChipLocationFilter = () => {
  const {
    searchParams: { cities, isOnline },
    unsetSearchParamField,
  } = useSearchQueryParams()

  const selectCount = cities?.length + (isOnline ? 1 : 0)

  const handleReset = () => {
    unsetSearchParamField('cities')
    unsetSearchParamField('isOnline')
  }

  return (
    <ChipPopover
      label="Platser"
      selectCount={selectCount}
      chipTestId="listFilterLocationChip"
      handleReset={handleReset}
    >
      <LocationFilterList />
    </ChipPopover>
  )
}
