import React from 'react'
import { PuffCard } from '@local/ActivitiesList/Components/PuffCards/Components/PuffCard'
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material'
import { getAppConfig, useEpiContent } from '@trr/app-shell-data'
import { AktivitetsbokningarContent } from '@local/Types'

const {
  COMMON: { MEDIA_URL },
} = getAppConfig()

export const RecordedPuff = () => {
  const {
    recordedPuffCard: { heading, body, actionText, actionUrl, imageUrl },
  } = useEpiContent<AktivitetsbokningarContent>()

  return (
    <PuffCard>
      {imageUrl && (
        <CardMedia
          component="img"
          alt={heading}
          height="140"
          image={MEDIA_URL + imageUrl}
        />
      )}
      <CardHeader title={heading} />
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="body1">{body}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="text" href={`${actionUrl}`} role="button">
          {actionText}
        </Button>
      </CardActions>
    </PuffCard>
  )
}
