import { IPart } from '@local/Activity/services/activity.types'
import { date, time, timespan } from '@trr/frontend-datetime'

export const getTimespan = (part: IPart) => {
  try {
    return timespan.format(part.start, part.end)
  } catch {
    // current implementation in @frontend-datetime doesn't have iOS 13 support
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
    }
    const start = new Intl.DateTimeFormat('sv-SE', options).format(
      new Date(part.start)
    )
    const end = new Intl.DateTimeFormat('sv-SE', options).format(
      new Date(part.end)
    )
    return `${start} - ${end}`
  }
}

export const dateAndTime = (start: string): string =>
  `${date.format(start)}|${time.format(start)}`
