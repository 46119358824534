/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'
import useToggle from '@local/Utils/Hooks/useToggle'
import { IMaterialLinks } from '@local/Activity/services/activity.types'
import { Article } from '@local/Activity/Components/Layout'
import VideoModal from '@local/ActivitiesList/Components/VideoModal'
import { parseYoutubeId } from '@local/Utils/Helpers/parseYoutubeId'
import { Link, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import OpenInNew from '@mui/icons-material/OpenInNew'
import PlayCircle from '@mui/icons-material/PlayCircle'
import TextSnippet from '@mui/icons-material/TextSnippet'

interface IMaterialPage {
  material: IMaterialLinks[]
}

const WEBINAR_FORMATS = ['youtube', 'youtu.be']

const MaterialPage: React.FunctionComponent<IMaterialPage> = ({ material }) => {
  const { toggle, handleToggle } = useToggle()
  const [selectedWebinar, setSelectedWebinar] = useState<IMaterialLinks>({
    url: '',
    description: '',
    contentType: '',
  })

  const handleWebinarClick = (link: IMaterialLinks) => {
    setSelectedWebinar(link)
    handleToggle()
  }

  const isWebinar = (url: string) =>
    WEBINAR_FORMATS.some((form) => url.includes(form))

  const getLinkIcon = (fileType: string, url: string) => {
    const regexPattern = /[^/]*/
    const regexArr = regexPattern.exec(fileType)
    const result = regexArr ? regexArr[0] : ''
    if (result.toLowerCase() === 'link') {
      return url.includes('trr.se') ? undefined : (
        <OpenInNew titleAccess="Extern länk" />
      )
    }
    return <TextSnippet titleAccess="Material" />
  }

  return (
    <Article dataTestId="materials-page">
      <List dense disablePadding>
        {material.map((link, i) => (
          <ListItem
            disableGutters
            divider={false}
            key={i}
            data-testid="MaterialListItem"
          >
            {isWebinar(link.url) ? (
              <>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <PlayCircle titleAccess="Video" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    component="button"
                    onClick={() => handleWebinarClick(link)}
                  >
                    {link.description}
                  </Link>
                </ListItemText>
              </>
            ) : (
              <>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  {getLinkIcon(link.contentType, link.url)}
                </ListItemIcon>
                <ListItemText>
                  <a
                    style={{ textDecoration: 'none' }}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Link component="span">{link.description}</Link>
                  </a>
                </ListItemText>
              </>
            )}
          </ListItem>
        ))}
      </List>
      <VideoModal
        toggled={toggle}
        handleClose={handleToggle}
        videoId={parseYoutubeId(selectedWebinar.url)}
        heading={selectedWebinar.description}
      />
    </Article>
  )
}

export default MaterialPage
