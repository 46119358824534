// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qtIrqXS0ewyLLSYnx8jq{position:relative;top:15px;min-height:350px}.qtIrqXS0ewyLLSYnx8jq svg{position:absolute;top:30%;left:50%;transform:translate(-50%, -50%);zoom:.55}@-moz-document url-prefix(){.qtIrqXS0ewyLLSYnx8jq svg{transform:translate(-50%, -50%) scale(55%)}}.EUAi3e9wGs87cfh0iqbX{position:absolute;width:100%;transform:translateY(80px);text-align:center}.qDOgSbZKRG1JG5zcCTdP{width:0px;padding-left:2px;display:inline-block}.qDOgSbZKRG1JG5zcCTdP:after{overflow:hidden;display:inline-block;vertical-align:bottom;animation:WV3RpnhfqzDeo8g_ydZT steps(4, end) 1500ms infinite;content:"…";width:0px}@keyframes WV3RpnhfqzDeo8g_ydZT{to{width:25px}}`, "",{"version":3,"sources":["webpack://./src/Activity/Components/BlobIllustration/BlobIllustration.module.scss"],"names":[],"mappings":"AAAA,sBAAA,iBACE,CAAA,QACA,CAAA,gBACA,CAAA,0BACA,iBACE,CAAA,OACA,CAAA,QACA,CAAA,+BACA,CAAA,QACA,CAAA,4BACA,0BANF,0CAOI,CAAA,CAAA,sBAKN,iBACE,CAAA,UACA,CAAA,0BACA,CAAA,iBACA,CAAA,sBAGF,SACE,CAAA,gBACA,CAAA,oBACA,CAAA,4BACA,eACE,CAAA,oBACA,CAAA,qBACA,CAAA,4DACA,CAAA,WACA,CAAA,SACA,CAAA,gCAIJ,GACE,UACE,CAAA","sourcesContent":[".blobWrapper {\n  position: relative;\n  top: 15px;\n  min-height: 350px;\n  svg {\n    position: absolute;\n    top: 30%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    zoom: 0.55;\n    @-moz-document url-prefix() {\n      transform: translate(-50%, -50%) scale(55%);\n    }\n  }\n}\n\n.blobText {\n  position: absolute;\n  width: 100%;\n  transform: translateY(80px);\n  text-align: center;\n}\n\n.dots {\n  width: 0px;\n  padding-left: 2px;\n  display: inline-block;\n  &:after {\n    overflow: hidden;\n    display: inline-block;\n    vertical-align: bottom;\n    animation: ellipsis steps(4, end) 1500ms infinite;\n    content: '\\2026';\n    width: 0px;\n  }\n}\n\n@keyframes ellipsis {\n  to {\n    width: 25px;\n  }\n}\n\n@-webkit-keyframes ellipsis {\n  to {\n    width: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blobWrapper": `qtIrqXS0ewyLLSYnx8jq`,
	"blobText": `EUAi3e9wGs87cfh0iqbX`,
	"dots": `qDOgSbZKRG1JG5zcCTdP`,
	"ellipsis": `WV3RpnhfqzDeo8g_ydZT`
};
export default ___CSS_LOADER_EXPORT___;
