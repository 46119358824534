import React from 'react'
import { ListItemIcon, type ListItemIconProps, styled } from '@mui/material'

interface CardListItemIconProps extends ListItemIconProps {
  alignTop?: boolean
}

const CustomListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== '$alignTop',
})<{ $alignTop: boolean }>(({ theme, $alignTop }) => ({
  minWidth: 'unset',
  marginRight: theme.spacing(2),
  position: 'relative',
  alignSelf: $alignTop ? 'baseline' : 'unset',
  top: $alignTop ? theme.spacing(1) : 'unset',
}))

export const CardListItemIcon = ({
  alignTop = false,
  children,
  ...props
}: CardListItemIconProps) => (
  <CustomListItemIcon $alignTop={alignTop} {...props}>
    {children}
  </CustomListItemIcon>
)
