export const getPreparationTime = (formattedTime: {
  hours: number
  minutes: number
}): string => {
  const hourText = `${formattedTime.hours.toString()}${
    formattedTime.minutes ? ' tim ' : ' tim'
  }`

  const minuteText = formattedTime.minutes
    ? `${formattedTime.minutes.toString()} min`
    : ''

  return `${formattedTime.hours ? hourText : ''}${minuteText}`
}
