/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import classNames from 'classnames'
import parse, {
  type DOMNode,
  domToReact,
  type HTMLReactParserOptions,
} from 'html-react-parser'
import DOMPurify from 'dompurify'
import { DataNode } from 'domhandler/lib/node'
import { replace, isEmpty, keys } from 'ramda'
import { Link as MuiLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface ReplaceAttributes<Content extends object = object> {
  attribs: Content
  children: DOMNode[] // Custom type to allow access to data on child node
}

interface HeadlineAttributes {
  id: string
  class: string
}

interface LinkAttributes {
  href?: string
  title?: string
}

interface IReplaceData extends ReplaceAttributes<Record<string, string>> {
  name: string
}

const domPurifyConfig = {
  ADD_TAGS: [
    'iframe',
    'factbox',
    'jmt',
    'jmtreport',
    'functionblock',
    'loginblock',
    'pullquote',
    'pulltext',
    'featuresblock',
    'testimonialblock',
    'bulletlistblock',
  ],
}

export const isLinkAnchor = (href: string): boolean => href.startsWith('#')

export const isLinkExternal = (href: string): boolean =>
  href.substring(0, 4) === 'http' ||
  href.startsWith('mailto:') ||
  href.startsWith('tel:')

export const replaceSelfClosingTags = (html: string): string => {
  // Needed so that the sanitizer doesn't remove data-content attributes
  // when self closing tags exist

  const tags = [
    { original: '&lt;br /&gt;', replacement: '&lt;br&gt;' },
    { original: '&lt;hr /&gt;', replacement: '&lt;hr&gt;' },
    { original: '<br />', replacement: '<br>' },
    { original: '<hr />', replacement: '<hr>' },
  ]

  tags.forEach((tag) => {
    html = html.replaceAll(tag.original, tag.replacement)
  })

  return html
}

const options = {
  replace: ({ attribs, children, name = '' }: IReplaceData) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const element = elementEnumeration.fromValue(name)
    if (!element) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return element.replace({ attribs, children })
  },
} as HTMLReactParserOptions

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asEnumeration = (dictionary: { [key: string]: { replace: any } }) =>
  Object.freeze({
    fromValue: (value: string) => dictionary[value],
  })

export const noCookie = (youtubeLink: string): string =>
  youtubeLink
    .replace('youtu.be', 'youtube.com/embed')
    .replace('watch?v=', 'embed/')
    .replace('youtube.com', 'youtube-nocookie.com')
    .replace('//youtube-nocookie', '//www.youtube-nocookie')
    .concat('?rel=0')

const elementEnumeration = asEnumeration({
  a: {
    replace: ({
      attribs: { href, title },
      children,
    }: ReplaceAttributes<LinkAttributes>) => (
      <MuiLink
        to={{ pathname: href }}
        title={title}
        target={isLinkExternal(href) ? '_blank' : '_self'}
        component={Link}
      >
        {domToReact(children, options)}
      </MuiLink>
    ),
  },

  h1: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography
        variant="h1"
        mb={2}
        mt={2}
        id={attribs.id}
        className={attribs.class}
      >
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h2: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography
        variant="h2"
        mb={2}
        mt={2}
        id={attribs.id}
        className={attribs.class}
      >
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h3: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography
        variant="h3"
        mb={2}
        mt={2}
        id={attribs.id}
        className={attribs.class}
      >
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h4: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography variant="h4" mb={2} id={attribs.id} className={attribs.class}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h5: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography variant="h5" mb={2} id={attribs.id} className={attribs.class}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  p: {
    replace: ({ children }: ReplaceAttributes) => (
      <Typography variant="body1" mb={2}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  ul: {
    replace: ({ children }: ReplaceAttributes) => (
      <Typography component="ul" mb={2}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  ol: {
    replace: ({ children }: ReplaceAttributes) => (
      <Typography component="ol" mb={2}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  li: {
    replace: ({ children }: ReplaceAttributes) => (
      <Typography component={'li'} mb={1}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
})

type wrapperTypes = 'span' | 'section'

interface IHTMLMapperProps {
  body: string
  replaceValues?: { [key: string]: string }
  wrapper?: wrapperTypes
  className?: string
}

const HTMLMapper = ({
  body,
  replaceValues = {},
  wrapper,
  className = '',
}: IHTMLMapperProps): JSX.Element => {
  const Wrapper: wrapperTypes = wrapper || 'span'

  const sanitized = DOMPurify.sanitize(
    replaceSelfClosingTags(body),
    domPurifyConfig
  )

  const output = isEmpty(replaceValues)
    ? sanitized
    : replace(
        new RegExp(keys(replaceValues).join('|'), 'gi'),
        (matched: string) => replaceValues[matched]
      )(sanitized)
  return (
    <Wrapper
      className={classNames('HTMLMapper', className)}
      data-testid={'EpiParser'}
    >
      {parse(output, options)}
    </Wrapper>
  )
}

export default HTMLMapper
