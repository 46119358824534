// TODO: Temporary function to catch activities without capacity. Remove when in prod and activities updated and just use the final case in the component.
export const getVacancyText = (
  vacancies: number,
  capacity: number,
  t: (key: string) => string
) => {
  if (capacity === 0) {
    const vac = vacancies === 1 ? 'vacancy' : 'vacancies'
    return `${vacancies} ${t(vac)}`
  }

  return `${vacancies} ${t('of')} ${capacity} ${t('vacanciesLeft')}`
}
