import React from 'react'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { Tabs } from '@mui/material'
import { LowercaseTab } from '@local/Components/LowercaseTab'

export enum TabName {
  Start = 'Start',
  Preparations = 'Preparations',
  Materials = 'Materials',
}

export interface ITabList {
  currentTab: TabName
  onChangeTab: (tab: React.MouseEvent<Element, MouseEvent>) => void
  hasPreparation: boolean
  hasMaterial: boolean
}

const ActivityTablist: React.FunctionComponent<ITabList> = ({
  currentTab,
  hasMaterial,
  hasPreparation,
  onChangeTab,
}) => {
  const t = useEpiCustomDictionary(['activity'])

  if (!hasMaterial && !hasPreparation) {
    return <></>
  }

  return (
    <Tabs
      value={currentTab}
      id="ActivityTabs"
      sx={{ mt: 0, mb: 4, ml: 0, mr: 0 }}
      variant="scrollable"
      scrollButtons="auto"
    >
      <LowercaseTab
        label={t('workshopTabStartText')}
        onClick={onChangeTab}
        data-tabname={TabName.Start}
        id="0-ActivityTabs"
        value={TabName.Start}
      />
      {hasPreparation && (
        <LowercaseTab
          label={t('workshopTabPreparationsText')}
          onClick={onChangeTab}
          data-tabname={TabName.Preparations}
          id="1-ActivityTabs"
          value={TabName.Preparations}
        />
      )}
      {hasMaterial && (
        <LowercaseTab
          label={t('workshopTabMaterialText')}
          onClick={onChangeTab}
          data-tabname={TabName.Materials}
          id="2-ActivityTabs"
          value={TabName.Materials}
        />
      )}
    </Tabs>
  )
}
export default ActivityTablist
