export const REGISTRATION_AVAILABILITY = {
  NO_REGISTRATION_REQUIRED: 'NO_REGISTRATION_REQUIRED',
  USER_NOT_CORRECT_ROLE: 'USER_NOT_CORRECT_ROLE',
  LOGIN_REQUIRED: 'LOGIN_REQUIRED',
  CAN_REGISTER: 'CAN_REGISTER',
  CAN_REGISTER_AS_RESERVE: 'CAN_REGISTER_AS_RESERVE',
  ALREADY_REGISTERED: 'ALREADY_REGISTERED',
  ALREADY_REGISTERED_RESERVE: 'ALREADY_REGISTERED_RESERVE',
  FULLY_BOOKED: 'FULLY_BOOKED',
  PENDING_OPERATION: 'PENDING_OPERATION',
  REQUIRES_ADVISOR_APPROVAL: 'REQUIRES_ADVISOR_APPROVAL',
  ACTIVITYROUND_PASSED: 'ACTIVITYROUND_PASSED',
  LAST_REGISTRATION_DATE_PASSED: 'LAST_REGISTRATION_DATE_PASSED',
} as const

export const LIFECYCLE_STATUS = {
  COMPLETED: 'COMPLETED',
  PUBLISHED: 'PUBLISHED',
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  DORMANT: 'DORMANT',
  COMMENCING: 'COMMENCING',
  ONGOING: 'ONGOING',
  UNKNOWN: 'UNKNOWN',
} as const

export const USER_REGISTRATION_STATUS = {
  Registered: 'Registered',
  Reserve: 'Reserve',
  Attended: 'Attended',
  Cancelled: 'Cancelled',
  DidNotAttend: 'DidNotAttend',
} as const
