import { BlobIllustration } from '@local/Activity/Components/BlobIllustration/BlobIllustration.illustration'
import { useGetActivityQuery } from '@local/Activity/services/activity.service'
import { ISignalRResponse } from '@local/Activity/services/signup.types'
import {
  connectActivityRoundUpdate,
  connectClientRegistrationFeedback,
  disconnectActivityRoundUpdate,
  disconnectClientRegistrationFeedback,
} from '@local/Utils/Helpers/signalRHelper'
import {
  signupSteps,
  useSearchQueryParams,
} from '@local/Utils/Hooks/useSearchQueryParams'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useAuthentication } from '@trr/app-shell-data'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface ISignupProgression {
  connection: HubConnection
  setSignalRResponse: React.Dispatch<React.SetStateAction<ISignalRResponse>>
}

const SignupProgression: React.FunctionComponent<ISignupProgression> = ({
  connection,
  setSignalRResponse,
}) => {
  const { sub } = useAuthentication()
  const { isCustomer } = useUserContext()
  const {
    searchParams: { id },
    setSearchParamField,
  } = useSearchQueryParams()
  const history = useHistory()

  const { refetch } = useGetActivityQuery(
    { id, isCustomer },
    { skip: id === '' }
  )

  useEffect(() => {
    if (history.action === 'POP') {
      setSearchParamField(signupSteps.Start, 'step')
    }
  })

  useEffect(() => {
    if (connection.state === HubConnectionState.Connected) {
      disconnectActivityRoundUpdate(connection, id)
      connectClientRegistrationFeedback(connection, id, sub, setSignalRResponse)
    }
    return () => {
      disconnectClientRegistrationFeedback(connection, id, sub)
      connectActivityRoundUpdate(connection, id, void refetch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection.state])

  useEffect(() => {
    const backButton: HTMLElement = document.querySelector(
      "button[class^='Breadcrumbs_InternalBackLink']"
    )
    if (backButton) {
      backButton.style.display = 'none'
    }

    return () => {
      if (backButton) {
        backButton.style.display = 'initial'
      }
    }
  }, [])

  return (
    <>
      <BlobIllustration />
    </>
  )
}

export default SignupProgression
