import {
  IGetFilteredActivitiesRequest,
  View,
} from '@local/ActivitiesList/services/activitiesList.types'

export const createActivityRequestParams = (
  searchParams: IGetFilteredActivitiesRequest,
  isCustomer: boolean
) => ({
  page: 1,
  pageSize: 15,
  view: isCustomer ? View.Kund : View.Anstalld,
  ...searchParams,
})
