import queryString from 'query-string'
import {
  QueryParamOptions,
  EncodedQuery,
  transformSearchStringJsonSafe,
} from 'use-query-params'

export const useQueryParamsOptions: QueryParamOptions = {
  searchStringToObject: (searchString: string): EncodedQuery => {
    const jsonSafe = transformSearchStringJsonSafe(searchString)
    const result = jsonSafe.replace(/%2b/i, '+')

    return queryString.parse(result)
  },
}
