import React from 'react'
import { FilterAccordion } from '@local/ActivitiesList/Components/Filtering/Components/FilterAccordion'
import { LocationFilterList } from '@local/ActivitiesList/Components/Filtering'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'

export const ExpandingLocationFilter = () => {
  const {
    searchParams: { cities, isOnline },
  } = useSearchQueryParams()

  const selectCount = cities?.length + (isOnline ? 1 : 0)

  return (
    <FilterAccordion title="Platser" selectCount={selectCount}>
      <LocationFilterList />
    </FilterAccordion>
  )
}
