import React from 'react'
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

interface FilterListItemProps {
  title: string
  active: boolean
  indeterminate?: boolean
  disabled?: boolean
  amount?: number
  handleOnClick: () => void
}

export const FilterListItem = ({
  title,
  amount,
  active,
  indeterminate,
  disabled,
  handleOnClick,
  ...props
}: FilterListItemProps) => (
  <ListItem
    secondaryAction={
      amount !== null && (
        <Typography
          variant="body1"
          sx={{
            color: (theme) => theme.palette.grey[amount > 0 ? 600 : 400],
          }}
        >
          {amount}
        </Typography>
      )
    }
    disablePadding
    divider={false}
    {...props}
  >
    <ListItemButton
      dense
      sx={{ paddingTop: 0, paddingBottom: 0 }}
      onClick={handleOnClick}
      disabled={disabled}
    >
      <ListItemIcon sx={{ minWidth: 0 }}>
        <Checkbox
          edge="start"
          checked={active}
          indeterminate={indeterminate}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': title }}
        />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  </ListItem>
)
