import React, { PropsWithChildren, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Button, IconButton, SwipeableDrawer, Typography } from '@mui/material'
import Close from '@mui/icons-material/Close'
import Tune from '@mui/icons-material/Tune'
import { FilterCountIndicator } from '@local/ActivitiesList/Components/Filtering/Components/FilterCountIndicator'

interface MobileFilterBarDrawerProps extends PropsWithChildren {
  title: string
  activeFilterCount?: number
  resetAllFilters: () => void
}

const FilterHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 1rem 0',
}))

const Wrapper = styled('div')(() => ({
  padding: '1rem',
}))

export const MobileFilterBarDrawer = ({
  title,
  activeFilterCount = 0,
  resetAllFilters,
  children,
}: MobileFilterBarDrawerProps) => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <>
      <Button
        startIcon={<Tune />}
        variant="text"
        sx={{ mb: 2 }}
        onClick={() => setIsFilterDrawerOpen(true)}
        data-testid="mobileFilterButton"
      >
        {title}
        {activeFilterCount > 0 && (
          <FilterCountIndicator>{activeFilterCount}</FilterCountIndicator>
        )}
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={isFilterDrawerOpen}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onOpen={() => setIsFilterDrawerOpen(true)}
        onClose={() => setIsFilterDrawerOpen(false)}
        PaperProps={{ sx: { width: '95%' }, 'data-testid': 'swipableDrawer' }}
      >
        <Wrapper>
          <FilterHeader>
            <Typography fontWeight={'bold'}>{title}</Typography>
            <IconButton
              sx={{ right: -10 }}
              onClick={() => setIsFilterDrawerOpen(false)}
            >
              <Close />
            </IconButton>
          </FilterHeader>
          <Button
            size="small"
            variant="text"
            sx={{ p: 2 }}
            onClick={resetAllFilters}
            disabled={activeFilterCount === 0}
          >
            Rensa allt
          </Button>
          {children}
        </Wrapper>
      </SwipeableDrawer>
    </>
  )
}
