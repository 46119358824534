import React, { PropsWithChildren } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'

export const ActivityPage = ({ children }: PropsWithChildren) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        gap: isDesktop ? '49px' : '2rem',
        flexDirection: isDesktop ? 'row' : 'column-reverse',
      }}
    >
      {children}
    </Box>
  )
}
