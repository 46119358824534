import { ValueOf } from '@local/App.types'
import { UUIDRegex } from '@local/Utils/Helpers/regexes'
import { matchStringByRegex } from '@local/Utils/Helpers/stringHelper'
import { useHistory } from 'react-router-dom'
import type { History } from 'history'
import {
  NumberParam,
  useQueryParams,
  withDefault,
  DelimitedArrayParam,
  QueryParamConfig,
  StringParam,
  BooleanParam,
} from 'use-query-params'

export const signupSteps = {
  Start: 'Start',
  Action: 'Action',
  Completed: 'Completed',
} as const

export const filterTabs = {
  All: 'all',
  Physical: 'physical',
  Online: 'online',
  Recorded: 'recorded',
} as const

export type IFilterTabs = ValueOf<typeof filterTabs>

export interface ISearchParamsComparison {
  areasOfInterest: string[]
  targetGroups: string[]
  cities: string[]
  activityForm: string[]
  isOnline: boolean
  id: string
  step: string
  tab: string
  fromSource: string
  openVideo: string
  page: number
}
interface ISearchParams {
  areasOfInterest: QueryParamConfig<string[], string[]>
  targetGroups: QueryParamConfig<string[], string[]>
  cities: QueryParamConfig<string[], string[]>
  activityForm: QueryParamConfig<string[], string[]>
  isOnline: QueryParamConfig<boolean, boolean>
  id: QueryParamConfig<string, string>
  step: QueryParamConfig<string, string>
  tab: QueryParamConfig<string, string>
  fromSource: QueryParamConfig<string, string>
  openVideo: QueryParamConfig<string, string>
  page: QueryParamConfig<number, number>
}

type ArraySearchParams = Pick<
  ISearchParams,
  'areasOfInterest' | 'targetGroups' | 'cities' | 'activityForm'
>

const getUuidFromUrl = (history: History) =>
  matchStringByRegex(history.location.pathname, UUIDRegex) ||
  matchStringByRegex(history.location.search, UUIDRegex)

const defaultParams = {
  areasOfInterest: withDefault(DelimitedArrayParam, []),
  targetGroups: withDefault(DelimitedArrayParam, []),
  cities: withDefault(DelimitedArrayParam, []),
  activityForm: withDefault(DelimitedArrayParam, []),
  isOnline: withDefault(BooleanParam, false),
  id: withDefault(StringParam, ''),
  step: withDefault(StringParam, ''),
  tab: withDefault(StringParam, filterTabs.All),
  fromSource: withDefault(StringParam, ''),
  openVideo: withDefault(StringParam, ''),
  page: withDefault(NumberParam, 1),
}

export const useSearchQueryParams = () => {
  const history: History = useHistory()
  const [searchParams, setSearchParams] = useQueryParams(defaultParams, {
    updateType: 'replaceIn',
    removeDefaultsFromUrl: true,
  })

  searchParams.id = getUuidFromUrl(history)

  const setSearchParamField = (
    value: string,
    fieldName: keyof ISearchParams,
    clearArray?: boolean
  ) => {
    if (typeof searchParams[fieldName] === 'number') {
      if (fieldName === 'page') {
        setSearchParams({
          [fieldName]: parseInt(value),
        })
      } else {
        setSearchParams({
          [fieldName]: parseInt(value),
          page: 1,
        })
      }

      return
    }

    if (typeof searchParams[fieldName] === 'boolean') {
      setSearchParams({
        [fieldName]: value === 'true',
      })

      return
    }

    if (typeof searchParams[fieldName] === 'string') {
      setSearchParams({
        [fieldName]: value,
      })

      return
    }

    const arrayFields = fieldName as keyof ArraySearchParams

    if (!searchParams[arrayFields]?.includes(value)) {
      setSearchParams({
        [arrayFields]:
          searchParams[arrayFields]?.length && !clearArray
            ? [...searchParams[arrayFields], value]
            : [value],
        page: 1,
      })
    } else {
      setSearchParams({
        [arrayFields]: searchParams[arrayFields].filter(
          (facet: string) => facet !== value
        ),
        page: 1,
      })
    }
  }

  const clearFilters = () => {
    setSearchParams({
      areasOfInterest: undefined,
      targetGroups: undefined,
      cities: undefined,
      activityForm: undefined,
      isOnline: false,
      page: 1,
    })
  }

  const unsetSearchParamField = (fieldName: keyof ISearchParams) => {
    setSearchParams({
      [fieldName]: undefined,
      page: 1,
    })
  }

  return {
    searchParams,
    unsetSearchParamField,
    setSearchParams,
    setSearchParamField,
    clearFilters,
  }
}
