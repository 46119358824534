import React, { PropsWithChildren } from 'react'
import { Button, Stack } from '@mui/material'

interface FilterWrapperProps {
  showResetFilters: boolean
  resetFilters: () => void
}

export const FilterWrapper = ({
  showResetFilters,
  resetFilters,
  children,
}: FilterWrapperProps & PropsWithChildren) => (
  <Stack paddingBottom={3} spacing={1} direction={'row'}>
    {children}
    {showResetFilters && (
      <Button
        size="small"
        variant="text"
        onClick={resetFilters}
        data-testid={'clearFiltersButton'}
      >
        Rensa allt
      </Button>
    )}
  </Stack>
)
