import { Tags } from '@local/Medarbetare/Services/medarbetareActivity.types'

export const getTagColor = (tag: Tags): 'error' | 'default' => {
  switch (tag) {
    case Tags.CANCELLED:
    case Tags.REQUIRES_ADVISOR_APPROVAL:
    case Tags.FULLY_BOOKED:
    case Tags.LAST_REGISTRATION_DATE_PASSED:
      return 'error'
    default:
      return 'default'
  }
}

export const filterTags = (tags: Tags[], tagsToHide: Tags[] = []): Tags[] => {
  if (
    tags.includes(Tags.FULLY_BOOKED) &&
    tags.includes(Tags.ONLY_RESERVE_VACANCIES_AVAILABLE)
  ) {
    tagsToHide.push(Tags.FULLY_BOOKED)
  }

  return tags.filter((tag) => !tagsToHide.includes(tag))
}

export const getTagText = (tag: Tags) => {
  switch (tag) {
    case Tags.CANCELLED:
      return 'Inställd'
    default:
      return tag
  }
}
