import React, { PropsWithChildren } from 'react'
import ActivityTablist, {
  TabName,
} from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import EpiParser from '@local/Activity/Components/Layout/EpiParser'
import { Article } from '@local/Activity/Components/Layout'
import { PreparationTime } from '@local/Activity/Components/Layout/PreparationContent/PreparationTime'
import { hasContent } from '@local/Utils/Helpers/stringHelper'
import MaterialPage from '@local/Activity/Components/MaterialPage/MaterialPage'
import { buildImageUrl } from '@local/Utils/Helpers/ImageBank/imageHelpers'
import { IActivityResponse } from '@local/Activity/services/activity.types'
import { IMedarbetareActivityResponse } from '@local/Medarbetare/Services/medarbetareActivity.types'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { ActivityImage } from '@local/Activity/Components/ActivityImage/ActivityImage'
import { themeOptions } from '@trr/mui-theme'
import { TabContext, TabPanel } from '@mui/lab'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { ReadMoreDialog } from '@local/Activity/Components/RegistrationSteps/ReadMoreDialog'
import { ActivityPage } from '@local/Activity/Components/Layout/ActivityPage'
import { ActivityDescription } from '@local/Activity/Components/ActivityDescription/ActivityDescription'
import { ClosingStatement } from '@local/Activity/Components/ClosingStatement/ClosingStatement'

interface IStartStep extends PropsWithChildren {
  currentTab: TabName
  onSetTab: (tab: TabName) => void
  stickyRef: React.MutableRefObject<HTMLDivElement>
  activity: IActivityResponse | IMedarbetareActivityResponse
}

const StartStep: React.FunctionComponent<IStartStep> = ({
  currentTab,
  onSetTab,
  children,
  stickyRef,
  activity,
}) => {
  const {
    activity: { name, preamble, imageUrl, description },
    workMaterial: { hasMaterial, links: materialLinks },
    preparation,
  } = activity
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const t = useEpiCustomDictionary(['activityList'])
  const tActivity = useEpiCustomDictionary(['activity'])
  const hasPreparation = Boolean(preparation?.description)

  const onTabChangeHandler = (event: React.MouseEvent<Element, MouseEvent>) => {
    onSetTab(event.currentTarget.getAttribute('data-tabname') as TabName)
  }

  const handleShowPopup = () => {
    setDialogOpen(true)
  }
  const handleClosePopup = () => {
    setDialogOpen(false)
  }

  const StartSection = () => (
    <Article>
      <EpiParser body={description} />
      <ActivityDescription form={activity.activity?.form} />
      <ClosingStatement />
    </Article>
  )

  return (
    <Box ref={stickyRef} data-testid="startStep">
      <Typography variant="h1" mb={2}>
        {name}
      </Typography>
      <ActivityPage>
        <Stack>
          <ReadMoreDialog
            open={dialogOpen}
            title={tActivity('internalNoteTitle')}
            content={activity?.administrativeInformation?.note}
            handleClose={handleClosePopup}
          />
          {activity?.administrativeInformation?.note && (
            <Alert
              severity="info"
              sx={{ mb: '20px' }}
              data-testid="administrativeInformationAlert"
              action={
                <Button size="small" variant="text" onClick={handleShowPopup}>
                  Läs mer
                </Button>
              }
            >
              {tActivity('internalNoteTitle')}
            </Alert>
          )}
          {hasContent(preamble) && (
            <Typography
              variant="body1"
              color={themeOptions.palette.text.secondary}
              mb={4}
            >
              {preamble}
            </Typography>
          )}
          <ActivityImage alt={name} url={buildImageUrl(imageUrl)} />
          <ActivityTablist
            currentTab={currentTab}
            onChangeTab={onTabChangeHandler}
            hasMaterial={hasMaterial}
            hasPreparation={hasPreparation}
          />
          <TabContext value={currentTab}>
            {hasMaterial || hasPreparation ? (
              <TabPanel
                id={'0-ActivityTabs'}
                value={TabName.Start}
                sx={{ padding: 0 }}
                data-testid="start-page"
              >
                <StartSection />
              </TabPanel>
            ) : (
              <StartSection />
            )}
            <TabPanel
              id={'1-ActivityTabs'}
              value={TabName.Preparations}
              sx={{ padding: 0 }}
            >
              <Article dataTestId="preparations-page">
                {preparation.timeRequiredInMinutes !== 0 && (
                  <PreparationTime time={preparation.timeRequiredInMinutes} />
                )}
                <div data-testid="preparation-description">
                  <EpiParser body={preparation.description} />
                </div>
              </Article>
            </TabPanel>
            <TabPanel
              id={'2-ActivityTabs'}
              value={TabName.Materials}
              sx={{ padding: 0 }}
            >
              <MaterialPage material={materialLinks} />
            </TabPanel>
          </TabContext>
        </Stack>
        <Stack>{children}</Stack>
      </ActivityPage>
    </Box>
  )
}

export default StartStep
