import { includes, isEmpty, isNil } from 'ramda'

type MapToOutput<I, U = I> = (v: I) => U

export const camelizeKebab = (s: string): string =>
  s.replace(/-./g, (x) => x.toUpperCase()[1])

export const camelize = (s: string) => {
  s = s.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return s.slice(0, 1).toLowerCase() + s.slice(1)
}

export const capitalize = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

export const isLinkExternal: MapToOutput<string, boolean> = (href: string) =>
  href.slice(0, 4) === 'http'

export const isTrrLink: MapToOutput<string, boolean> = (href: string) =>
  href.includes('trr.se')

export const isLinkAnchor = (href: string) => href.startsWith('#')

const linkFileFormats = ['doc', 'docx', 'pdf']

export const isLinkFile = (href: string) =>
  linkFileFormats.filter((s) => includes(s, href.slice(href.length - 4)))
    .length !== 0

export const prependSlash = (url: string): string => {
  if (!url) {
    return url
  }
  return url?.startsWith('/') ? url : `/${url}`
}

export const trimTrailingSlash = (s: string): string => s.replace(/\/$/, '')

export const hasContent: MapToOutput<string, boolean> = (content: string) =>
  !isEmpty(content) && !isNil(content)

export const matchStringByRegex = (input: string, matcher: RegExp): string =>
  matcher.exec(input)?.toString()?.split(',')[0] || ''
