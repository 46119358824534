import {
  FacetValueTypes,
  IGetFilteredActivitiesResponse,
} from '@local/ActivitiesList/services/activitiesList.types'
import { IGetFilteredActivitiesMedarbetareResponse } from '@local/Medarbetare/Services/medarbetareActivitiesList.types'

const getFacetFromActivity = (
  activities:
    | IGetFilteredActivitiesResponse
    | IGetFilteredActivitiesMedarbetareResponse,
  facetName: FacetValueTypes
) => activities?.facets.find((x) => x.field === facetName)?.values

export const getTargetGroupFacetsFromActivity = (
  activities:
    | IGetFilteredActivitiesResponse
    | IGetFilteredActivitiesMedarbetareResponse
) =>
  getFacetFromActivity(activities, FacetValueTypes.NextActivityRoundTargetGroup)

export const getCityFacetsFromActivity = (
  activities:
    | IGetFilteredActivitiesResponse
    | IGetFilteredActivitiesMedarbetareResponse
) => getFacetFromActivity(activities, FacetValueTypes.Cities)

export const getActivityformFacetsFromActivity = (
  activities:
    | IGetFilteredActivitiesResponse
    | IGetFilteredActivitiesMedarbetareResponse
) => getFacetFromActivity(activities, FacetValueTypes.ActivityForm)

export const getOnlineFacetsFromActivity = (
  activities:
    | IGetFilteredActivitiesResponse
    | IGetFilteredActivitiesMedarbetareResponse
) => getFacetFromActivity(activities, FacetValueTypes.IsOnline)

export const getAreaOfInterestFacetsFromActivity = (
  activities:
    | IGetFilteredActivitiesResponse
    | IGetFilteredActivitiesMedarbetareResponse
) => getFacetFromActivity(activities, FacetValueTypes.AreasOfInterest)
