import React from 'react'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { ListItemText, Typography } from '@mui/material'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import Info from '@mui/icons-material/Info'

const CallToActionHasStarted: React.FunctionComponent = () => {
  const t = useEpiCustomDictionary(['activity'])

  return (
    <CardListItem>
      <CardListItemIcon aria-hidden>
        <Info />
      </CardListItemIcon>
      <ListItemText>
        <Typography variant={'subtitle2'}>
          {t('occasionHasStartedInfo')}
        </Typography>
      </ListItemText>
    </CardListItem>
  )
}

export default CallToActionHasStarted
