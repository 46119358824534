import React from 'react'
import { FilterAccordion } from '@local/ActivitiesList/Components/Filtering/Components/FilterAccordion'
import { TargetGroupFilterList } from '@local/ActivitiesList/Components/Filtering'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'

export const ExpandingTargetGroupFilter = () => {
  const {
    searchParams: { targetGroups },
  } = useSearchQueryParams()

  return (
    <FilterAccordion title="Målgrupper" selectCount={targetGroups?.length}>
      <TargetGroupFilterList />
    </FilterAccordion>
  )
}
