import React, { PropsWithChildren } from 'react'

import s from './ListCardWrapper.module.scss'

const ListCardWrapper = (props: PropsWithChildren) => (
  <div className={s.ListWrapper} data-testid="activityCardWrapper">
    {props.children}
  </div>
)

export default ListCardWrapper
