import React from 'react'
import { FilterListItem } from '@local/ActivitiesList/Components/Filtering/Components/FilterListItem'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { List, Typography } from '@mui/material'
import { capitalize } from '@local/Utils/Helpers/stringHelper'
import { useGetFilteredActivitiesQuery } from '@local/ActivitiesList/services'
import { useGetFilteredMedarbetareActivitiesQuery } from '@local/Medarbetare/Services/medarbetareActivitiesList.service'
import { getTargetGroupFacetsFromActivity } from '@local/ActivitiesList/Components/Filtering/Utils/filterHelpers'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'

export const TargetGroupFilterList = () => {
  const {
    searchParams: {
      targetGroups: pickedTargetGroups,
      areasOfInterest,
      cities,
      isOnline,
      activityForm,
    },
    setSearchParamField,
  } = useSearchQueryParams()

  const shouldTrack = useIsFeatureEnabled('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()
  const { isMedarbetare, isCustomer } = useUserContext()
  const searchParams = {
    areasOfInterest,
    cities,
    isOnline: isOnline ? isOnline : undefined,
    activityForm,
  }
  const { data: publicTargetGroups } = useGetFilteredActivitiesQuery(
    { searchParams, isCustomer: isCustomer },
    {
      skip: isMedarbetare,
    }
  )
  const { data: medarbetareTargetGroups } =
    useGetFilteredMedarbetareActivitiesQuery(
      { searchParams, isCustomer: isCustomer },
      {
        skip: !isMedarbetare,
      }
    )

  const activities = isMedarbetare
    ? medarbetareTargetGroups
    : publicTargetGroups
  const targetGroups = getTargetGroupFacetsFromActivity(activities)

  const handleSetTargetGroups = (targetGroup: string) => {
    if (shouldTrack && !pickedTargetGroups.includes(targetGroup)) {
      trackClick('Filtrering', { label: `Målgrupp: ${targetGroup}` })
    }
    setSearchParamField(targetGroup, 'targetGroups')
  }

  if (!targetGroups || targetGroups.length === 0) {
    return <Typography ml={1.5}>Inga målgrupper kunde hittas</Typography>
  }

  return (
    <List data-testid="listFilterTargetGroupsList" disablePadding>
      {targetGroups.map((group) => (
        <FilterListItem
          title={capitalize(group.name)}
          key={group.name}
          amount={group.count}
          active={pickedTargetGroups.includes(group.name)}
          handleOnClick={() => handleSetTargetGroups(group.name)}
          disabled={
            !pickedTargetGroups.includes(group.name as string) &&
            group.count === 0
          }
        />
      ))}
    </List>
  )
}
